import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { startIdentityAuth } from '@riotgames/kr-identity-sdk';
import Config from 'lib/Config';
import * as Api from 'lib/Api';
import LegalPhone from 'components/Agree/Legal/Phone';
import LegalProxy from 'components/Agree/Legal/Proxy';
import LegalProxyKIds from 'components/Agree/Legal/ProxyKIds';
import LegalEtc from 'components/Agree/Legal/Etc';
import { authIdentity, logOut } from 'reducer/AuthReducer';

function Legal(props) {
  const goLink = (link) => () => {
    props.history.push(link);
  };
  const hrefLogin = () => {
    alert('로그인이 필요합니다!');
    props.logOut();
    goLink('/')();
  };

  const agreeNext = () => {
    if (props.agreeState === 'evidence') {
      goLink('/Details/File')();
    } else if (props.agreeState === 'proxy') {
      goLink('/Details/Delegate')();
    } else if (props.agreeState === 'other' || props.agreeState === 'PROXY_NO_EVIDENCE') {
      goLink('/Details/Pay')();
    }
  };

  const onAuthSuccess = ({ identityToken }) => {
    //console.log(identityToken);
    props.authIdentity(props.agreeState, props.history, identityToken, 'Legal', props.selfAuthToken);
  };
  const onAuthFailed = () => {
    alert('본인인증 실패');
  };

  const agreeCheck = () => {
    if (!Api.getCookies(Config.PVPNET_TOKEN_KEY)) {
      hrefLogin();
      return;
    }
    /*본인인증창 호출*/
    startIdentityAuth({
      serviceType: Config.identityAuth.serviceCode,
      environment: Config.identityAuth.env,
      isPopup: true,
      successCallback: onAuthSuccess,
      failCallback: onAuthFailed
    });
  };

  useEffect(() => {}, [props.agreeState, props.parentSelfAuthToken, props.parentName]);

  return (
    <div className="inner">
      {props.agreeState === 'proxy' && props.authState.age < 14 && (
        <LegalProxyKIds realName={props.authState.realName} parentName={props.parentName} />
      )}

      <div className="col-cont-tit-box">
        {(props.agreeState === 'evidence' && (
          <p className="col-cont-tit">개인정보 수집 및 이용에 관한 법정대리인 동의</p>
        )) ||
          (props.agreeState === 'proxy' && (
            <p className="col-cont-tit">대리결제 관련 권리 양도 및 통지권한 위임에 대한 법정대리인 동의</p>
          )) ||
          ((props.agreeState === 'other' || props.agreeState === 'PROXY_NO_EVIDENCE') && (
            <p className="col-cont-tit">개인정보 수집 및 이용에 관한 법정대리인 동의</p>
          )) || <Redirect to="/" />}
      </div>

      {props.agreeState === 'proxy' && <LegalProxy realName={props.authState.realName} parentName={props.parentName} />}

      <div className="col-cont-certify-box">
        <p className="col-cont-tit">본인인증</p>
        <p className="col-cont-txt">
          {props.agreeState === 'proxy' ? <>위 항목에 </> : <>아래 항목에 </>}
          동의를 위해 법정대리인의 본인인증이 필요합니다.
        </p>
        <button
          type="button"
          className={cx(['col-cont-btn', 'type-02'], { on: !props.parentSelfAuthToken })}
          onClick={agreeCheck}
        >
          본인인증
        </button>
      </div>

      {props.agreeState === 'evidence' && (
        <LegalPhone realName={props.authState.realName} parentName={props.parentName} />
      )}
      {(props.agreeState === 'other' || props.agreeState === 'PROXY_NO_EVIDENCE') && (
        <LegalEtc realName={props.authState.realName} parentName={props.parentName} />
      )}

      <div className="col-cont-btn-box col-cont-btn-box-column">
        <button
          type="button"
          className="col-cont-btn type-02"
          className={cx(['col-cont-btn', 'type-02'], { on: props.parentSelfAuthToken })}
          onClick={agreeNext}
        >
          계속하기
        </button>
        <button type="button" className="col-cont-btn" onClick={goLink('/Details/Transaction')}>
          이전으로 돌아가기
        </button>
      </div>
    </div>
  );
}

export default connect(
  ({ AuthReducer, PageStateReducer }) => ({
    authState: AuthReducer.authState,
    selfAuthToken: AuthReducer.selfAuthToken,
    parentSelfAuthToken: AuthReducer.parentSelfAuthToken,
    parentName: AuthReducer.parentName,
    agreeState: PageStateReducer.agreeState
  }),
  (dispatch) => ({
    authIdentity: bindActionCreators(authIdentity, dispatch),
    logOut: bindActionCreators(logOut, dispatch)
  })
)(Legal);
