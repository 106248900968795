/*액션상수*/
export const FETCH_DETAILLIST = 'ListReducer/FETCH_DETAILLIST';
export const LIST_SUCCESS = 'ListReducer/LIST_SUCCESS';
export const LIST_FAILURE = 'ListReducer/LIST_FAILURE';
export const LIST_SELECT = 'ListReducer/LIST_SELECT';

export const FETCH_ACCOUNTSTATUS = 'ListReducer/FETCH_ACCOUNTSTATUS';
export const ACCOUNTSTATUS_SUCCESS = 'ListReducer/ACCOUNTSTATUS_SUCCESS';
export const ACCOUNTSTATUS_FAILURE = 'ListReducer/ACCOUNTSTATUS_FAILURE';

/*액션함수*/
export const fetchDetailList = (history) => ({
  type: FETCH_DETAILLIST,
  payload: { history }
});
export const ListSuccess = (detailLists) => ({
  type: LIST_SUCCESS,
  payload: { detailLists }
});
export const ListFailure = (error) => ({
  type: LIST_FAILURE,
  payload: { error }
});
export const ListSelect = (selectList) => ({
  type: LIST_SELECT,
  payload: { selectList }
});

export const fetchAccountStatus = (history) => ({
  type: FETCH_ACCOUNTSTATUS,
  payload: { history }
});
export const AccountStatusSuccess = (accountStatus) => ({
  type: ACCOUNTSTATUS_SUCCESS,
  payload: { accountStatus }
});
export const AccountStatusFailure = (error) => ({
  type: ACCOUNTSTATUS_FAILURE,
  payload: { error }
});

/*기본상태 및 리듀서*/
const initialState = {
  detailLists: [],
  listLoading: true,
  selectList: {},
  ListsError: false,

  accountStatus: {},
  accountStatusError: false
};
export function ListReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DETAILLIST:
      return {
        ...state
      };

    case LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        detailLists: action.payload.detailLists
      };

    case LIST_FAILURE:
      return {
        ...state,
        listLoading: true,
        listsError: true
      };

    case LIST_SELECT:
      return {
        ...state,
        selectList: action.payload.selectList
      };

    case FETCH_ACCOUNTSTATUS:
      return {
        ...state
      };

    case ACCOUNTSTATUS_SUCCESS:
      return {
        ...state,
        accountStatus: action.payload.accountStatus
      };

    case ACCOUNTSTATUS_FAILURE:
      return {
        ...state,
        accountStatusError: true
      };

    default:
      return state;
  }
}
