import { takeEvery, put, call, delay } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as Api from 'lib/Api';
import * as ActionAuth from 'reducer/AuthReducer';
import * as ActionProxyPay from 'reducer/ProxyPayReducer';
import * as ActionLoad from 'reducer/LoadReducer';
import * as ActionPageState from 'reducer/PageStateReducer';
import Config from '../lib/Config';

function* AuthIdentitySaga(action) {
  /*본인인증*/
  console.log('saga!:', action);
  const jsonData = {};

  yield put(ActionLoad.showLoad());

  try {
    /*인증토큰 디코딩API호출*/
    const authType = action.payload.agreeState;
    const identityToken = action.payload.identityToken;
    const identityInfo = yield call(Api.getPersonalInfo, identityToken);
    //console.log(identityInfo.data);

    /*--개인동의 페이지인경우--*/
    if (action.payload.authType === 'Privacy') {
      /*본인인증 결과 저장 API호출(string 대문자로 변경해서 넘김)*/
      jsonData.selfAuthToken = identityToken;
      const { status } = yield call(Api.postIdentityAuth, authType.toUpperCase(), JSON.stringify(jsonData));
      console.log(status);
      if (status === 200 || status === 201) {
        /*개인인증토큰 저장*/
        yield put(ActionAuth.authIdentitySelf(identityToken));
      }

      /*API호출후 라우팅*/
      if (action.payload.authState.age < 14 || (action.payload.authState.age < 19 && authType === 'proxy')) {
        action.payload.history.push('/Details/Legal');
      } else {
        if (authType === 'evidence') {
          action.payload.history.push('/Details/File');
        } else if (authType === 'proxy') {
          action.payload.history.push('/Details/Delegate');
        } else if (authType === 'other') {
          action.payload.history.push('/Details/Pay');
        } else if (authType === 'PROXY_NO_EVIDENCE') {
          action.payload.history.push('/Details/Pay');
        }
      }
    } else {
      /*--대리인증 페이지인경우--*/

      /*대리인증 결과 저장 API호출(string 대문자로 변경해서 넘김)*/
      jsonData.selfAuthToken = action.payload.selfAuthToken;
      jsonData.parentSelfAuthToken = identityToken;
      const { status } = yield call(Api.postIdentityAuth, authType.toUpperCase(), JSON.stringify(jsonData));
      console.log(status);
      if (status === 200 || status === 201) {
        /*대리인증토큰 및 이름 저장*/
        yield put(ActionAuth.authIdentityParentself(identityToken));
        yield put(ActionAuth.authParentselfName(identityInfo.data.realName));
      }
    }
  } catch (error) {
    /*에러처리*/
    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`인증정보가 올바르지 않습니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }

    /*세션토큰 만료일시*/
    yield put(ActionAuth.authCheckFailure(error.response));
    console.log('!세션만료!');
    yield alert('세션이 만료되었습니다.');

    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));

    /*
    yield put(ActionAuth.authCheckFailure(error.response));
    yield put(ActionPageState.errorState(error.response ? error.response.status : 500));
    action.payload.history.push('/Error');
    */
  }

  yield put(ActionLoad.hideLoad());
}

function* AuthCheckSaga(action) {
  /*본인인증 결과 조회*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  try {
    /*결제상태로*/
    yield put(ActionPageState.paymentState(true));

    /*authType 받음*/
    const authType = action.payload.agreeState;

    /*저장된 인증토큰 리셋*/
    yield put(ActionAuth.authIdentityReset());

    /*본인인증 결과 조회 API로 호출(string 대문자로 변경해서 넘김)*/
    const { data } = yield call(Api.getIdentityAuth, authType.toUpperCase());
    console.log(data);

    /*본인인증 결과 조회 상태설정*/
    yield put(ActionPageState.agreeState(authType));

    /*본인인증 결과 조회 API로 호출된 데이터 저장*/
    yield put(ActionAuth.authCheckSuccess(data));

    /*비인가 대리 충전업체일시 최종납부값 저장 및 19세 미만 대리인증*/
    if (authType === 'proxy') {
      yield put(ActionProxyPay.unpaidSum(action.payload.priceProxy));

      if ((data.selfAuth && data.age >= 19) || (data.parentSelfAuth && data.selfAuth && data.age < 19)) {
        /*인증된경우--(만19세이상.개인인증완료),(만19세미만.부모인증완료,개인인증완료)--인증부분 점프*/
        action.payload.history.push('/Details/Delegate');
      } else if (!data.parentSelfAuth && data.selfAuth && data.age < 19) {
        /*인증필요시 (만19세미만.개인인증완료)--개인인증부분 점프*/
        action.payload.history.push('/Details/Legal');
      } else {
        /*인증필요시--개인정보 페이지로*/
        action.payload.history.push('/Details/Privacy');
      }
    } else {
      /*대리인증이 아닐시는 14세미만 체크*/
      if ((data.selfAuth && data.age >= 14) || (data.parentSelfAuth && data.selfAuth && data.age < 14)) {
        /*인증된경우--(만14세이상.개인인증완료),(만14세미만.부모인증완료,개인인증완료)--인증부분 점프*/
        if (authType === 'evidence') {
          action.payload.history.push('/Details/File');
        } else if (authType === 'other') {
          action.payload.history.push('/Details/Pay');
        } else if (authType === 'PROXY_NO_EVIDENCE') {
          action.payload.history.push('/Details/Pay');
        }
      } else if (!data.parentSelfAuth && data.selfAuth && data.age < 14) {
        /*인증필요시 (만14세미만.개인인증완료)--개인인증부분 점프*/
        action.payload.history.push('/Details/Legal');
      } else {
        /*인증필요시--개인정보 페이지로*/
        action.payload.history.push('/Details/Privacy');
      }
    }
  } catch (error) {
    /*에러처리*/

    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`잘못된 요청입니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }

    /*세션토큰 만료일시*/
    yield put(ActionAuth.authCheckFailure(error.response));
    console.log('!세션만료!');
    yield alert('세션이 만료되었습니다.');

    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));

    /*
    if (error.response) {
      yield put(ActionAuth.authCheckFailure(error.response));
      yield put(ActionPageState.errorState(error.response.status));
      action.payload.history.push('/Error');
    } else {
      console.log('!로그아웃 처리!');
      yield put(ActionAuth.logOut());
      action.payload.history.push('/');
    }*/
  }

  yield put(ActionLoad.hideLoad());
}

export function* authIdentityLink() {
  yield takeEvery(ActionAuth.AUTH_IDENTITY, AuthIdentitySaga);
}

export function* authCheckLink() {
  yield takeEvery(ActionAuth.AUTH_CHECK, AuthCheckSaga);
}
