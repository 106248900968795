import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { startIdentityAuth } from '@riotgames/kr-identity-sdk';
import Config from 'lib/Config';
import * as Api from 'lib/Api';
import PrivacyPhone from 'components/Agree/Privacy/Phone';
import PrivacyProxy from 'components/Agree/Privacy/Proxy';
import PrivacyEtc from 'components/Agree/Privacy/Etc';
import { authIdentity, logOut } from 'reducer/AuthReducer';

function Privacy(props) {
  const goLink = (link) => () => {
    props.history.push(link);
  };

  const hrefLogin = () => {
    alert('로그인이 필요합니다!');
    props.logOut();
    goLink('/')();
  };

  const onAuthSuccess = ({ identityToken }) => {
    //console.log(identityToken);
    props.authIdentity(props.agreeState, props.history, identityToken, 'Privacy', '', props.authState);
  };
  const onAuthFailed = () => {
    alert('본인인증 실패');
  };

  const agreeCheck = () => {
    if (!Api.getCookies(Config.PVPNET_TOKEN_KEY)) {
      hrefLogin();
      return;
    }
    /*본인인증창 호출*/
    startIdentityAuth({
      serviceType: Config.identityAuth.serviceCode,
      environment: Config.identityAuth.env,
      isPopup: true,
      successCallback: onAuthSuccess,
      failCallback: onAuthFailed
    });
  };

  useEffect(() => {}, [props.agreeState, props.authState]);

  return (
    <div className="inner">
      <div className="col-cont-tit-box">
        {(props.agreeState === 'evidence' && <p className="col-cont-tit">개인정보 수집, 이용 및 위탁에 대한 동의</p>) ||
          (props.agreeState === 'proxy' && (
            <p className="col-cont-tit">
              대리결제 관련 권리 양도 및 통지권한 위임에 관한
              <br />
              개인정보 수집, 이용 및 위탁에 대한 동의
            </p>
          )) ||
          ((props.agreeState === 'other' || props.agreeState === 'PROXY_NO_EVIDENCE') && (
            <p className="col-cont-tit">개인정보 수집, 이용 및 위탁에 대한 동의</p>
          )) || <Redirect to="/" />}
      </div>

      {(props.agreeState === 'evidence' && <PrivacyPhone />) ||
        (props.agreeState === 'proxy' && <PrivacyProxy />) ||
        ((props.agreeState === 'other' || props.agreeState === 'PROXY_NO_EVIDENCE') && <PrivacyEtc />)}

      <div className="col-cont-tit-box">
        <p className="col-cont-tit type-small">
          본인은 위 개인정보 수집, 이용 및 처리 위탁에 동의합니다.
          <br />
          (휴대전화 또는 신용카드 본인인증이 필요하며 미성년자의 경우 별도의 법정대리인 동의 절차가 필요합니다.)
        </p>
      </div>
      <div className="col-cont-btn-box col-cont-btn-box-column">
        <button type="button" href="#" className="col-cont-btn type-02 on" onClick={agreeCheck}>
          동의
        </button>
        <button type="button" href="#" className="col-cont-btn" onClick={goLink('/Details/Transaction')}>
          이전으로 돌아가기
        </button>
      </div>
    </div>
  );
}

export default connect(
  ({ AuthReducer, PageStateReducer }) => ({
    authState: AuthReducer.authState,
    agreeState: PageStateReducer.agreeState
  }),
  (dispatch) => ({
    authIdentity: bindActionCreators(authIdentity, dispatch),
    logOut: bindActionCreators(logOut, dispatch)
  })
)(Privacy);
