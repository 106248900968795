import React from 'react';

function ProxyKIds(props) {
  return (
    <>
      <div className="col-cont-tit-box">
        <p className="col-cont-tit">
          대리결제 관련 권리 양도 및 통지권한 위임에 관한 <br />
          개인정보 수집 및 이용에 관한 법정대리인 동의
        </p>
      </div>
      <div className="col-cont-agree-box">
        <p className="col-cont-agree-tit">
          본인은 [{props.realName ? props.realName : '계정 소유자 성명'}]의 법정대리인인 [
          {props.parentName ? props.parentName : '법정대리인 성명'}]으로 아래 사항을 확인하고 이에 동의합니다.
        </p>

        <div className="col-cont-agree-txt">
          <p>• 개인정보 수집 및 이용목적</p>
          <ul>
            <li>
              “라이엇게임즈”가 수집한 개인정보는 “대리결제”와 관련하여 발생하였거나 장래 발생할 채무불이행, 불법행위,
              기타 원인에 따른 손해배상채권 또는 부당이득반환청구권 등 일체의 권리를 “라이엇게임즈”에 양도하고, 이에
              기하여 “라이엇게임즈”가 관련 소송 수행 및 집행을 하기 위한 목적으로 이용됩니다.
            </li>
          </ul>
        </div>
        <div className="col-cont-agree-txt">
          <p>• 수집하는 개인정보 항목</p>
          <ul>
            <li>- 회원에 대해 추가 수집하는 정보 (휴대전화 번호)</li>
            <li>- 법정대리인에 대한 정보 (법정대리인의 성명, 생년월일, 휴대전화 번호)</li>
            <li>
              - “대리결제”관련 정보 (“대리결제” 상대방의 정보, “대리결제”약속(계약) 입증자료, “대리결제”상대방의 은행명,
              계좌번호 및 예금주명, 송금일, 송금액, “대리결제”송금 입증자료 등)
            </li>
          </ul>
        </div>
        <div className="col-cont-agree-txt">
          <p>• 개인정보의 보유 및 이용기간</p>
          <p>
            "라이엇게임즈"는 개인정보의 수집 목적이 달성되거나 관련 법령에서 정한 기간이 만료되면, 수집된 개인정보를
            파기합니다. 이때 출력된 형태의 개인정보는 파쇄 및 소각되며 전자 파일은 복구 불가능한 방법으로 파기합니다.
          </p>
        </div>
        <div className="col-cont-agree-txt">
          <p>• 개인정보의 처리 위탁</p>
          <p>“라이엇게임즈”는 수집한 개인정보를 관련 소송 수행 및 집행을 위하여 아래와 같이 그 취급을 위탁합니다. </p>
        </div>
        <div className="col-cont-table-box">
          <table>
            <caption>
              납부내역에 관한 표이며, 결제일, 거래번호, 휴대전화번호, 미납금액, 납무금액, 상태, 납부 등으로 구성되어
              있습니다.
            </caption>
            <thead>
              <tr>
                <th scope="col">
                  <div className="cont-table-txt-wrap">수탁자</div>
                </th>
                <th scope="col">
                  <div className="cont-table-txt-wrap">목적</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="cont-table-txt-wrap">법무법인(유한) 태평양</div>
                </td>
                <td>
                  <div className="cont-table-txt-wrap">대리 결제 업체에 대한 소송 수행 </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="cont-table-txt-wrap">(주)UBASE</div>
                </td>
                <td>
                  <div className="cont-table-txt-wrap">자료 확인 및 문의 상담</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-cont-agree-txt">
          <p>• 동의를 거부할 권리 및 동의를 거부할 경우 불이익</p>
          <p>
            귀하는 위 사항에 대해 동의를 거부할 수 있습니다. 다만, 위 개인정보의 수집 및 이용에 관한 동의는 “대리결제”와
            관련한 귀하의 권리를 “라이엇게임즈”가 양도받고 관련한 법적절차를 진행하기 위해 필수적인 정보입니다. 위
            사항에 동의하지 않는 경우 본 절차를 진행할 수 없습니다.
          </p>
        </div>
      </div>
    </>
  );
}

export default ProxyKIds;
