import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'assets/scss/contents/Input/radio.scss';

function Radio({ name, number, span, defaultValue, register, value }) {
  return (
    <div className="input_r">
      <input
        type="radio"
        name={name}
        id={name + '-' + number}
        value={value}
        ref={register}
        defaultChecked={defaultValue}
      />
      <label htmlFor={name + '-' + number}>{span}</label>
    </div>
  );
}

export default Radio;
