import { takeEvery, put, call, delay } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as Api from 'lib/Api';
import * as ActionAuth from 'reducer/AuthReducer';
import * as ActionLoad from 'reducer/LoadReducer';
import * as ActionPageState from 'reducer/PageStateReducer';
import Config from '../lib/Config';

function* LogInLinkSaga(action) {
  /*RSO 로그인 체크*/
  console.log('saga!:', action);
  console.log('PVPNET_TOKEN_KEY= ', Config.PVPNET_TOKEN_KEY);
  console.log('id토큰= ', Api.getCookies(Config.PVPNET_TOKEN_KEY));

  if (!Api.getCookies(Config.PVPNET_TOKEN_KEY)) {
    console.log('!로그아웃 유지상태!');
    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));

    return false;
  }

  yield buildSession(action);
}

function* LogOutSaga(action) {
  console.log('saga!:', action);
  const { history } = action.payload;

  Api.removeStorage(Config.BEARERTOKEN);

  if (history && history.location && history.location.pathname !== '/') {
    history.push('/');
  }
}

function* buildSession(action) {
  if (Api.getStorage(Config.BEARERTOKEN)) {
    console.log('!로그인 유지상태!');
    yield put(ActionAuth.logIn());

    return false;
  }

  yield put(ActionLoad.showLoad());

  try {
    const { data } = yield call(Api.postSessionToken);
    console.log(data);
    /* Store token in sessionStorage */
    Api.addStorage(Config.BEARERTOKEN, data.accessToken);

    /*로그인 상태 처리*/
    console.log('!로그인 처리!');
    yield put(ActionAuth.logIn());
  } catch (error) {
    /*404사용자는 미수금 내역이 없기 때문에 사이트 이용을 할 수 없도록 막습니다.*/
    if (error.response && error.response.status === 404) {
      yield put(ActionAuth.logInLinkFailure());
      yield put(ActionLoad.hideLoad());
      return;
    }

    /*로그인 실패*/
    console.log('!로그인 실패!');
    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));
  }

  yield put(ActionLoad.hideLoad());
}

export function* watchLogInLink() {
  yield takeEvery(ActionAuth.LOGIN_LINK, LogInLinkSaga);
}

export function* watchLogOut() {
  yield takeEvery(ActionAuth.LOGOUT, LogOutSaga);
}
