import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

function Done(props) {
  const { proxyCount } = props.accountStatus;

  const goLink = (link) => () => {
    props.history.push(link);
  };

  useEffect(() => {
    //console.log(props.submitState);
  }, [props.submitState]);

  return (
    <div className="inner ty2">
      <div className="col-cont-tit-box mgb0">
        <div>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="40" fill="#D03739" />
            <path
              d="M19.9111 38.9488L36.4778 54.7002L60.3167 26.9301"
              stroke="#F8F8F8"
              strokeWidth="8"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        {(props.submitState === 'UnlockReducer/UNLOCK_REQUEST' && (
          <>
            <p className="col-cont-tit mgt25">계정잠금 해제 완료!</p>
            <p className="col-cont-txt mgt35">
              계정잠금 해제 신청이 완료 되었습니다.
              <br />
              (단, 미납 요금으로 인한 계정잠금 외에 게임 이용 제재내역이 있는 경우, 계정잠금이 해제되지 않을 수
              있습니다.)
            </p>
          </>
        )) ||
          (props.submitState === 'PaymentReducer/PAYMENT_RESPONSE' && (
            <>
              <p className="col-cont-tit mgt25">납부가 완료되었습니다.</p>
              <p className="col-cont-txt mgt35">
                거래번호 {props.selectList.txNo} 의 미납 요금 납부가 완료되었습니다.
                <br />
                <br />
                모든 미납 요금을 납부하셨다면 계정잠금 해제를 신청할 수 있습니다.
                <br />
                미납 내역이 남아있는 경우, 아래 버튼을 눌러 계속해서 미납 요금을 납부하실 수 있습니다.
              </p>
              <div className="col-cont-btn-box col-cont-btn-box-column mgt35">
                <button type="button" className="col-cont-btn" onClick={goLink('/Details')}>
                  내역으로 돌아가기
                </button>
              </div>
            </>
          )) || <Redirect to="/" />}
      </div>
    </div>
  );
}

export default connect(({ PageStateReducer, ListReducer }) => ({
  submitState: PageStateReducer.submitState,
  selectList: ListReducer.selectList,
  accountStatus: ListReducer.accountStatus
}))(Done);
