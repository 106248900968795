import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import Text from 'components/Input/Text';
import SelectBox from 'components/Input/SelectBox';

function FieldArray(props) {
  const { control, register, defaultValues, errors } = props;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'proxyCompanyPhone'
  });

  return (
    <>
      <ul className="field-array">
        {fields.map((item, index) => {
          //console.log(item);
          return (
            <li key={item.id}>
              <div className="select-input-w">
                <SelectBox
                  name={`proxyCompanySns${index}`}
                  options={[
                    { value: 'kakao', label: '카카오톡' },
                    { value: 'jungo', label: '중고나라' },
                    { value: 'itembay', label: '아이템베이' },
                    { value: 'itemmania', label: '아이템매니아' },
                    { value: 'etc', label: '기타' }
                  ]}
                  control={control}
                  onChange={([selected]) => {
                    // React Select return object instead of value for selection
                    //console.log(selected);
                    return { value: selected.value, label: selected.label };
                  }}
                  defaultValue={{ value: '', label: '서비스명' }}
                />

                <Text name={`proxyCompanySnsId${index}`} label={'아이디'} register={register} errors={errors} />
                <button type="button" className="close" onClick={() => remove(index)}>
                  Delete
                </button>
              </div>
            </li>
          );
        })}
      </ul>

      {fields.length < 3 && (
        <button
          type="button"
          className="col-cont-btn small"
          onClick={() => {
            append();
          }}
        >
          아이디 추가
        </button>
      )}
    </>
  );
}

export default FieldArray;
