import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

import 'assets/scss/contents/Input/text.scss';

function Text(props) {
  const {
    name,
    span,
    label,
    defaultValue,
    register,
    readOnly,
    className,
    hanleChangeP,
    handleFocusP,
    handleBlurP,
    errors
  } = props;
  //console.log(props);

  const [inputFlag, setInputFlag] = useState(false);
  const [inputCase, setInputCase] = useState('on');

  const hanleChange = () => {};

  const handleFocus = () => {
    if (!readOnly) {
      setInputCase('focus');
    }

    setInputFlag(true);
  };

  const handleBlur = (e) => {
    //console.log(e.target.value);
    if (e.target.value) {
      setInputFlag(true);
      setInputCase('on');
    } else {
      setInputFlag(false);
    }
  };

  useEffect(() => {
    //console.log(errors);
  }, [inputFlag, inputCase]);

  return (
    <div
      className={cx(['input_t'], {
        readonly: readOnly,
        [inputCase]: inputFlag || defaultValue,
        [className]: className
      })}
    >
      <div>
        <input
          type="text"
          name={name}
          ref={register}
          defaultValue={defaultValue}
          readOnly={readOnly}
          onChange={hanleChangeP ? hanleChangeP : hanleChange}
          onBlur={handleBlurP ? handleBlurP : handleBlur}
          onFocus={handleFocusP ? handleFocusP : handleFocus}
        />
        <label>{label}</label>
        <span>{span ? span : label}</span>
      </div>
      {errors[name] && <p className="err">{errors[name].message}</p>}
    </div>
  );
}

export default Text;
