import React, { useEffect } from 'react';
import { connect } from 'react-redux';

function Error(props) {
  const goLink = (link) => () => {
    props.history.push(link);
  };

  useEffect(() => {
    //console.log(props.errorState);
  }, [props.errorState]);

  return (
    <div className="inner ty2">
      <div className="col-cont-tit-box mgb0">
        <p className="col-cont-txt symbol">{props.errorState ? props.errorState : 404}</p>

        <p className="col-cont-txt bold mgt50">
          {(props.errorState === 400 && (
            <>
              잘못된 요청입니다.
              <br />
              처음 화면으로 돌아가서 다시 시도해주세요.
            </>
          )) ||
            (props.errorState === 401 && (
              <>
                인증되지 않은 접근입니다.
                <br />
                처음 화면으로 돌아가서 로그인 후 다시 시도 바랍니다.
              </>
            )) ||
            (props.errorState === 406 && <>허용되지 않은 접근입니다.</>) ||
            (props.errorState === 500 && (
              <>
                서버 오류입니다.
                <br />
                잠시 후 다시 시도해주세요.
              </>
            )) ||
            (props.errorState === 404 && (
              <>
                페이지가 변경되었거나 삭제되어
                <br />
                요청하신 페이지를 찾을 수 없습니다.
              </>
            )) || (
              <>
                페이지가 변경되었거나 삭제되어
                <br />
                요청하신 페이지를 찾을 수 없습니다.
              </>
            )}
        </p>

        <div className="col-cont-btn-box mgt50 pdt50">
          <button type="button" className="col-cont-btn type-02 on" onClick={goLink('/')}>
            돌아가기
          </button>
        </div>
      </div>
    </div>
  );
}

//export default Error;
export default connect(({ PageStateReducer }) => ({
  errorState: PageStateReducer.errorState
}))(Error);
