import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import numeral from 'numeral';

import { ListSelect } from 'reducer/ListReducer';
import { authCheck } from 'reducer/AuthReducer';
import Text from 'components/Input/Text';

function ProxyNoEvidence(props) {
  const [inputSum, setInputSum] = useState(0);
  const [inputSale, setInputSale] = useState(0);
  const { paymentAmount, unpaidAmount } = props.selectList;

  const { register, handleSubmit, errors, reset, setValue, getValues } = useForm({
    mode: 'onChange'
  });

  const goLink = (link) => () => {
    props.history.push(link);
  };

  /**
   * @param {string} agreeState 동의상태->evidence: 휴대폰 납입확인증, proxy: 대리결제 , other: 기타, PROXY_NO_EVIDENCE: 대리결제 증빙없음
   **/
  const chkAuth = (agreeState) => (data, e) => {
    props.authCheck(agreeState, props.history);
    e.preventDefault();
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    setInputSum(paymentAmount);
    setInputSale(Math.floor((paymentAmount * 0.35) / 100) * 100);
  }, [paymentAmount]);

  useEffect(() => {
    if (window.sessionStorage.getItem('selectList')) {
      const selectList = JSON.parse(window.sessionStorage.getItem('selectList'));
      props.ListSelect(selectList);
    }
  }, []);

  return (
    <>
      {props.paymentState ? (
        <div className="inner">
          <div className="col-cont-tit-box mgb0">
            <p className="col-cont-tit">비인가 대리 충전업체를 이용한 경우</p>
            <p className="col-cont-txt mgt30">
              비인가 대리 충전 업체를 통한 결제(이하 "대리결제")는 여러가지 문제를 유발하며 RP의 가치를 훼손시키는
              행위라 할 수 있습니다.
              <br />
              이에 라이엇게임즈는 결제대행사 등과 긴밀히 협조하여 새로운 결제 정책을 적용하는 등 대리결제를 근절하기
              위한 노력을 진행하고 있습니다.
              <br />
              이러한 노력의 일환으로 비인가 대리충전업체와 플레이어 여러분 사이에 발생한 일체의 권리를 양도 받아
              <br />
              대리결제를 진행하는 것 처럼 기만하고 실제로는 해당 요금을 미납한 업체를 대상으로 민, 형사상의 조치도
              고려하고 있습니다.
              <br />
              <br />
              라이엇게임즈는 플레이어 여러분께서 모든 손실을 부담하지 않도록 일부 금액을 미납 요금에서 감면하여 드리고자
              합니다.
            </p>
          </div>

          <div className="col-cont-tit-box mgb20 mgt50">
            <p className="col-cont-tit type-small">증빙을 제출하지 않을 경우 납부해야 할 미납요금</p>
            <div className="col-cont-txt mgt10">
              관련 증빙이 없는 경우, 상품 가격의 35%만 납부하고 계정 잠금을 해제하실 수 있습니다.
              <i className="cont-table-icon-hover single mgl5">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect
                    x="1.7218"
                    y="8.5"
                    width="11"
                    height="11"
                    rx="2"
                    transform="rotate(-45 1.7218 8.5)"
                    fill="#6D6D6D"
                  />
                  <path
                    d="M9.32227 10.371L9.36627 9.73851C10.8953 9.49651 11.7423 8.68251 11.7423 7.41201C11.7423 6.02051 10.6313 5.13501 8.71177 5.13501C8.33777 5.13501 7.90877 5.16251 7.60077 5.20651L7.70527 6.65301C7.93627 6.63101 8.24427 6.60901 8.54127 6.60901C9.59177 6.60901 10.1583 6.92801 10.1583 7.52201C10.1583 8.16551 9.58627 8.50101 8.52477 8.50101H7.99677V10.371H9.32227ZM8.66227 11.042C8.10127 11.042 7.61177 11.5205 7.61177 12.087C7.61177 12.648 8.10127 13.1375 8.66227 13.1375C9.21777 13.1375 9.70177 12.648 9.70177 12.0815C9.70177 11.5205 9.21777 11.042 8.66227 11.042Z"
                    fill="#B0B0B0"
                  />
                </svg>
                <div className="tooltip_hover">비인가 대리 충전업체의 할인율의 평균입니다.</div>
              </i>
            </div>

            <form style={{ width: '360px', margin: '0 auto' }}>
              <Text
                className={'mgt30'}
                name={'price_unpaid'}
                label={'미납금액 (원)'}
                register={register}
                errors={errors}
                defaultValue={numeral(paymentAmount).format('0,0')}
                readOnly={true}
              />
              <Text
                className={'mgt15'}
                name={'price_sale'}
                label={'납부해야 하는 금액 (원)'}
                register={register}
                errors={errors}
                defaultValue={numeral(Math.floor((paymentAmount * 0.35) / 100) * 100).format('0,0')}
                readOnly={true}
              />
            </form>
          </div>

          <div className="col-cont-tit-box mgb50 mgt50">
            <p className="col-cont-tit type-middle">
              {unpaidAmount <= inputSum && unpaidAmount <= inputSale
                ? numeral(unpaidAmount).format('0,0,0')
                : inputSum <= inputSale
                ? numeral(inputSum).format('0,0,0')
                : numeral(inputSale).format('0,0,0')}{' '}
              원을 납부하셔야 합니다.
            </p>
          </div>

          <div className="col-cont-btn-box col-cont-btn-box-column">
            <button
              type="button"
              className={cx(['col-cont-btn', 'type-02'], {
                on: !errors.price_proxy && inputSum > 0
              })}
              onClick={handleSubmit(chkAuth('PROXY_NO_EVIDENCE'))}
            >
              납부하기
            </button>
            <button type="button" className="col-cont-btn" onClick={goLink('/Details/Transaction')}>
              이전으로 돌아가기
            </button>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default connect(
  ({ ListReducer, AuthReducer, PageStateReducer }) => ({
    selectList: ListReducer.selectList,
    loginState: AuthReducer.loginState,
    paymentState: PageStateReducer.paymentState
  }),
  (dispatch) => ({
    ListSelect: bindActionCreators(ListSelect, dispatch),
    authCheck: bindActionCreators(authCheck, dispatch)
  })
)(ProxyNoEvidence);
