import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

function Fail(props) {
  const goLink = (link) => () => {
    props.history.push(link);
  };

  useEffect(() => {
    //console.log(props.paymentResponseError);
  }, [props.submitState, props.paymentResponseError]);

  return (
    <div className="inner ty2">
      <div className="col-cont-tit-box mgb0">
        <div>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="40" fill="#A837D0" />
            <path
              d="M45.072 17H35.088L36.24 47H43.92L45.072 17ZM40.112 53.44C36.848 53.44 34 56.224 34 59.488C34 62.752 36.848 65.6 40.112 65.6C43.344 65.6 46.16 62.752 46.16 59.488C46.16 56.224 43.344 53.44 40.112 53.44Z"
              fill="white"
            />
          </svg>
        </div>

        {(props.submitState === 'UnlockReducer/UNLOCK_REQUEST' && (
          <>
            <p className="col-cont-tit mgt25">계정잠금 해제 실패</p>
            <p className="col-cont-txt mgt35">
              계정잠금 해제에 실패하였습니다.
              <br />
              이전 단계로 돌아가 다시 시도하여 주십시오.
              <br />
              잠금해제 실패가 계속해서 반복되는 경우 고객지원을 통해 문의 바랍니다.
            </p>
          </>
        )) ||
          (props.submitState === 'PaymentReducer/PAYMENT_RESPONSE' && (
            <>
              <p className="col-cont-tit mgt25">{props.paymentResponseError.errorMessage}</p>
              <p className="col-cont-txt mgt35">
                이전 단계로 돌아가 다시 시도하여 주십시오.
                <br />
                결제 실패가 계속해서 반복되는 경우 고객지원을 통해 문의 바랍니다.
              </p>
              <div className="col-cont-btn-box col-cont-btn-box-column mgt35">
                <button type="button" className="col-cont-btn" onClick={goLink('/Details')}>
                  내역으로 돌아가기
                </button>
              </div>
            </>
          )) || <Redirect to="/" />}
      </div>
    </div>
  );
}

export default connect(({ PageStateReducer, PaymentReducer }) => ({
  submitState: PageStateReducer.submitState,
  paymentResponseError: PaymentReducer.paymentResponseError
}))(Fail);
