import React from "react";
import Loader from "assets/images/loader.svg"

import "assets/scss/contents/Loading/loading.scss"

function Loading() {
	
	return (
		<div id="pageloading">
			<div className="pageloading">
				<img src={Loader} alt="loading"/>
			</div>
		</div>
	);
}

export default Loading;
