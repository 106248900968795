import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import Config from 'lib/Config';

function Header(props) {
  //console.log(props);

  const css = `
	.riotbar-root{display:none;}
	body.riotbar-present {margin-top:0 !important}
	`;

  const goLink = (link) => () => {
    props.history.push(link);
  };

  const logout = () => {
    Cookies.remove(Config.PVPNET_TOKEN_KEY, { path: '/', domain: 'riotgames.com' });
    Cookies.remove('id_token', { path: '/', domain: 'riotgames.com' });
    goLink('/')();
    window.location.reload();
  };

  return (
    <>
      <style>{css}</style>
      <header id="header">
        <nav className="default_top_nav">
          <div className="nav_logo_wrap">
            <span className="second-logo default_nav_logo">
              <Link to="/">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                    fill="#D13639"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2932 18.5603L26.2665 11.0137L35.5999 13.2803L33.9465 29.9203H29.1999L28.3199 17.8137L27.8132 17.947L26.9865 29.9203H23.3865L21.6799 19.867L21.1732 20.027L21.3065 29.9203H17.9199L15.9732 21.627L15.4665 21.787L16.0265 29.9203H12.7732L10.2932 18.5603ZM22.3467 31.547L23.5467 34.587L33.2533 36.987L33.7333 32.107L22.3467 31.547Z"
                    fill="#F9F9F9"
                  />
                </svg>
              </Link>
            </span>
          </div>
          <div className="default_menu_wrap">
            <NavLink to="/Details" activeClassName="on" className="default_menu_link">
              세부내역 확인
            </NavLink>
            <NavLink to="/Unlock" activeClassName="on" className="default_menu_link">
              계정잠금 해제
            </NavLink>
            <a
              href="https://support-leagueoflegends.riotgames.com/hc/ko"
              target="_blank"
              rel="noopener noreferrer"
              className="default_menu_link"
            >
              자주묻는 질문
            </a>
            <a
              href="https://support-leagueoflegends.riotgames.com/hc/ko"
              target="_blank"
              rel="noopener noreferrer"
              className="default_menu_link"
            >
              고객지원
            </a>
          </div>
          <div className="default_lang_wrap">
            {props.loginState ? (
              <button type="button" className="default_menu_btn" onClick={logout}>
                로그아웃
              </button>
            ) : (
              <a
                data-riotbar-link-id="login"
                href="https://stage.auth.kr.riotgames.com/rso/login?returnUrl=https://dev.anti-fraud.kr.riotgames.com/"
                style={{ color: '#fff' }}
              >
                <button type="button" className="default_menu_btn">
                  로그인
                </button>
              </a>
            )}
          </div>
        </nav>
      </header>
    </>
  );
}

//export default Header;
export default connect(({ AuthReducer }) => ({
  loginState: AuthReducer.loginState
}))(Header);
