import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Transaction from 'container/Details/Transaction';
import File from 'container/File/File';
import Pay from 'container/Pay/Pay';
import PayReturn from 'container/Pay/PayReturn';
import ProxyPay from 'container/Proxy/ProxyPay';
import ProxyNoEvidence from 'container/Proxy/ProxyNoEvidence';
import Delegate from 'container/Proxy/Delegate';
import Notfound from 'container/Error/Error';

/*동의 레퍼*/
import Privacy from 'container/Agree/Privacy';
import Legal from 'container/Agree/Legal';

function DetailsRoutes() {
  return (
    <Switch>
      <Route path="/Details/Transaction" component={Transaction} />
      <Route path="/Details/File" component={File} />
      <Route path="/Details/Pay" component={Pay} />
      <Route path="/Details/PayReturn" component={PayReturn} />
      <Route path="/Details/ProxyPay" component={ProxyPay} />
      <Route path="/Details/ProxyNoEvidence" component={ProxyNoEvidence} />
      <Route path="/Details/Delegate" component={Delegate} />
      <Route path="/Details/Privacy" component={Privacy} />
      <Route path="/Details/Legal" component={Legal} />

      <Route path="/Details/*" component={Notfound} />
    </Switch>
  );
}

export default DetailsRoutes;
