import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import 'assets/scss/contents/Input/selectBox.scss';

function SelectBox({ name, options, control, onChange, defaultValue }) {
  return (
    <Controller
      as={Select}
      className={'select-container'}
      classNamePrefix={'select'}
      name={name}
      options={options}
      control={control}
      onChange={onChange}
      defaultValue={defaultValue}
    />
  );
}

export default SelectBox;
