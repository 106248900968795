/*액션상수*/
export const UNPAIDSUM = 'ProxyPayReducer/UNPAIDSUM';

export const PROXYPAY_SUBMIT = 'ProxyPayReducer/PROXYPAY_SUBMIT';
export const PROXYPAY_SUBMIT_SUCCESS = 'ProxyPayReducer/PROXYPAY_SUBMIT_SUCCESS';
export const PROXYPAY_SUBMIT_FAILURE = 'ProxyPayReducer/PROXYPAY_SUBMIT_FAILURE';

/*액션함수*/
export const unpaidSum = (priceProxy) => ({
  type: UNPAIDSUM,
  payload: { priceProxy }
});

export const proxyPaySubmit = (formData, history) => ({
  type: PROXYPAY_SUBMIT,
  payload: { formData, history }
});
export const proxyPaySubmitSuccess = (completedTxNo) => ({
  type: PROXYPAY_SUBMIT_SUCCESS,
  payload: { completedTxNo }
});
export const proxyPaySubmitFailure = (error) => ({
  type: PROXYPAY_SUBMIT_FAILURE,
  payload: { error }
});

/*기본상태 및 리듀서*/
const initialState = {
  priceProxy: 0,
  completedTxNo: 0,
  submitError: false
};
export function ProxyPayReducer(state = initialState, action) {
  switch (action.type) {
    case UNPAIDSUM:
      return {
        ...state,
        priceProxy: action.payload.priceProxy
      };

    case PROXYPAY_SUBMIT:
      return {
        ...state
      };

    case PROXYPAY_SUBMIT_SUCCESS:
      return {
        ...state,
        completedTxNo: action.payload.completedTxNo
      };

    case PROXYPAY_SUBMIT_FAILURE:
      return {
        ...state,
        submitError: true
      };

    default:
      return state;
  }
}
