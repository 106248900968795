import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import DetailsRoutes from 'components/Routes/DetailsRoutes';
import RestrictionModal from 'components/Modal/RestrictionModal';
import DetailListRows from 'components/List/DetailListRows';
import { logOut } from 'reducer/AuthReducer';
import { fetchDetailList, fetchAccountStatus } from 'reducer/ListReducer';
import Config from 'lib/Config';
import * as Api from 'lib/Api';
import 'assets/scss/contents/details.scss';

function Details(props) {
  //console.log(props);

  const hrefLogin = () => {
    alert('로그인이 필요합니다!');
    props.logOut();
  };

  const beforeunloadEvent = () => {
    if (props.popupPayment) {
      props.popupPayment.close();
    }
  };

  useEffect(() => {
    if (!Api.getCookies(Config.PVPNET_TOKEN_KEY)) hrefLogin();
  }, [Api.getCookies(Config.PVPNET_TOKEN_KEY)]);

  useEffect(() => {
    if (Api.getBearerToken()) {
      props.fetchDetailList(props.history);
      props.fetchAccountStatus(props.history);
    }
  }, []);

  useEffect(() => {
    /*결제팝업이 다른 경로로 라우팅시에 닫기*/
    if (props.popupPayment && props.location.pathname !== '/Details/Pay') {
      props.popupPayment.close();
    }
  }, [props.detailLists, props.accountStatus, props.popupPayment, props.location.pathname]);

  useEffect(() => {
    /*결제팝업 새로고침시 닫기*/
    window.addEventListener('beforeunload', beforeunloadEvent);
    return () => {
      window.removeEventListener('beforeunload', beforeunloadEvent);
    };
  });

  return (
    <>
      {(Api.getCookies(Config.PVPNET_TOKEN_KEY) && !props.logInLinkError && (
        <>
          {props.location.pathname === '/Details' && (
            <div className="inner">
              <div className="col-cont-tit-box">
                <p className="col-cont-tit">납부안내</p>
                <div className="col-cont-txt-w">
                  <p className="col-cont-txt">
                    ① 본인 명의 휴대폰으로 결제하였으나 미납된 요금이 있는 경우, 통신요금 납부확인서 (PDF/JPG/PNG)를
                    제출하여 주시기 바랍니다.
                    <br />
                    (동일 명의 휴대폰으로 여러번 결제하였지만 납부확인서가 1장인 경우, 각 거래번호별로 동일한 파일을
                    첨부하여 주시기 바랍니다.)
                  </p>
                  <p className="col-cont-txt">
                    ② 대리 충전 업체를 이용하여 결제하여 미납된 금액이 있는 경우, 해당 업체로 입금한 입금 확인증 및 거래
                    사실을 입증할 수 있는 자료를
                    <br />
                    제출하여 주시기 바랍니다.
                  </p>
                  <p className="col-cont-txt">
                    ③ 기타 사유의 경우 미납 금액 전액을 납부하고 계정잠금을 해제할 수 있습니다.
                    <br />
                    (대리 충전 업체를 이용하였으나 증빙이 없는 경우, 지인이 미납한 경우 혹은 본인 명의 휴대폰이지만
                    납부확인서 발급이 불가능한 경우 등)
                  </p>
                </div>
              </div>

              <div className="col-cont-table-box">
                <p className="col-cont-tit">세부내역</p>
                <div className="col-cont-txt-w mgt0 txtc">
                  <p className="col-cont-txt mgb0">미납 결제건은 [납부하기]를 눌러 납부 절차를 진행할 수 있습니다.</p>
                  <p className="col-cont-txt">
                    모든 미납 내역을 납부 완료한 경우 상단의 [계정잠금 해제]를 눌러 잠금을 해제할 수 있습니다.
                  </p>
                </div>
                <table>
                  <caption>
                    납부내역에 관한 표이며, 결제일, 거래번호, 휴대전화번호, 미납금액, 납부하실 금액, 상태, 납부 등으로
                    구성되어 있습니다.
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">결제일</div>
                      </th>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">거래번호</div>
                      </th>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">
                          휴대전화번호
                          <i className="cont-table-icon-hover">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1.7218"
                                y="8.5"
                                width="11"
                                height="11"
                                rx="2"
                                transform="rotate(-45 1.7218 8.5)"
                                fill="white"
                              />
                              <path
                                d="M9.32227 10.371L9.36627 9.73851C10.8953 9.49651 11.7423 8.68251 11.7423 7.41201C11.7423 6.02051 10.6313 5.13501 8.71177 5.13501C8.33777 5.13501 7.90877 5.16251 7.60077 5.20651L7.70527 6.65301C7.93627 6.63101 8.24427 6.60901 8.54127 6.60901C9.59177 6.60901 10.1583 6.92801 10.1583 7.52201C10.1583 8.16551 9.58627 8.50101 8.52477 8.50101H7.99677V10.371H9.32227ZM8.66227 11.042C8.10127 11.042 7.61177 11.5205 7.61177 12.087C7.61177 12.648 8.10127 13.1375 8.66227 13.1375C9.21777 13.1375 9.70177 12.648 9.70177 12.0815C9.70177 11.5205 9.21777 11.042 8.66227 11.042Z"
                                fill="#B0B0B0"
                              />
                            </svg>
                            <div className="tooltip_hover">개인정보 보호를 위하여 마스킹 처리되어 표시됩니다.</div>
                          </i>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">
                          미납금액
                          <i className="cont-table-icon-hover">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1.7218"
                                y="8.5"
                                width="11"
                                height="11"
                                rx="2"
                                transform="rotate(-45 1.7218 8.5)"
                                fill="white"
                              />
                              <path
                                d="M9.32227 10.371L9.36627 9.73851C10.8953 9.49651 11.7423 8.68251 11.7423 7.41201C11.7423 6.02051 10.6313 5.13501 8.71177 5.13501C8.33777 5.13501 7.90877 5.16251 7.60077 5.20651L7.70527 6.65301C7.93627 6.63101 8.24427 6.60901 8.54127 6.60901C9.59177 6.60901 10.1583 6.92801 10.1583 7.52201C10.1583 8.16551 9.58627 8.50101 8.52477 8.50101H7.99677V10.371H9.32227ZM8.66227 11.042C8.10127 11.042 7.61177 11.5205 7.61177 12.087C7.61177 12.648 8.10127 13.1375 8.66227 13.1375C9.21777 13.1375 9.70177 12.648 9.70177 12.0815C9.70177 11.5205 9.21777 11.042 8.66227 11.042Z"
                                fill="#B0B0B0"
                              />
                            </svg>
                            <div className="tooltip_hover">상품 구매시 청구된 금액입니다.</div>
                          </i>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">
                          납부하실 금액
                          <i className="cont-table-icon-hover">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1.7218"
                                y="8.5"
                                width="11"
                                height="11"
                                rx="2"
                                transform="rotate(-45 1.7218 8.5)"
                                fill="white"
                              />
                              <path
                                d="M9.32227 10.371L9.36627 9.73851C10.8953 9.49651 11.7423 8.68251 11.7423 7.41201C11.7423 6.02051 10.6313 5.13501 8.71177 5.13501C8.33777 5.13501 7.90877 5.16251 7.60077 5.20651L7.70527 6.65301C7.93627 6.63101 8.24427 6.60901 8.54127 6.60901C9.59177 6.60901 10.1583 6.92801 10.1583 7.52201C10.1583 8.16551 9.58627 8.50101 8.52477 8.50101H7.99677V10.371H9.32227ZM8.66227 11.042C8.10127 11.042 7.61177 11.5205 7.61177 12.087C7.61177 12.648 8.10127 13.1375 8.66227 13.1375C9.21777 13.1375 9.70177 12.648 9.70177 12.0815C9.70177 11.5205 9.21777 11.042 8.66227 11.042Z"
                                fill="#B0B0B0"
                              />
                            </svg>
                            <div className="tooltip_hover">계정 잠금 해제를 위해 납부해야 할 금액입니다.</div>
                          </i>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">
                          상태
                          <i className="cont-table-icon-hover">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="1.7218"
                                y="8.5"
                                width="11"
                                height="11"
                                rx="2"
                                transform="rotate(-45 1.7218 8.5)"
                                fill="white"
                              />
                              <path
                                d="M9.32227 10.371L9.36627 9.73851C10.8953 9.49651 11.7423 8.68251 11.7423 7.41201C11.7423 6.02051 10.6313 5.13501 8.71177 5.13501C8.33777 5.13501 7.90877 5.16251 7.60077 5.20651L7.70527 6.65301C7.93627 6.63101 8.24427 6.60901 8.54127 6.60901C9.59177 6.60901 10.1583 6.92801 10.1583 7.52201C10.1583 8.16551 9.58627 8.50101 8.52477 8.50101H7.99677V10.371H9.32227ZM8.66227 11.042C8.10127 11.042 7.61177 11.5205 7.61177 12.087C7.61177 12.648 8.10127 13.1375 8.66227 13.1375C9.21777 13.1375 9.70177 12.648 9.70177 12.0815C9.70177 11.5205 9.21777 11.042 8.66227 11.042Z"
                                fill="#B0B0B0"
                              />
                            </svg>
                            <div className="tooltip_hover">
                              현재 처리 상태를 확인하실 수 있으며, 서류에 문제가 있을 경우 반려사유가 표기 됩니다.
                            </div>
                          </i>
                        </div>
                      </th>
                      <th scope="col">
                        <div className="cont-table-txt-wrap">납부</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(props.ListsError && (
                      <tr>
                        <td colSpan="7">
                          <div className="cont-table-txt-wrap">오류가 발생하였습니다. 잠시후 다시시도해주세요.</div>
                        </td>
                      </tr>
                    )) ||
                      (props.listLoading && (
                        <tr>
                          <td colSpan="7">
                            <div className="cont-table-txt-wrap">로딩중 입니다.</div>
                          </td>
                        </tr>
                      )) ||
                      (!props.detailLists.length && (
                        <tr>
                          <td colSpan="7">
                            <div className="cont-table-txt-wrap">리스트가 없습니다.</div>
                          </td>
                        </tr>
                      )) ||
                      props.detailLists.map((data) => {
                        return (
                          <DetailListRows
                            key={data.unpaidId}
                            history={props.history}
                            unpaidId={data.unpaidId}
                            txDate={data.txDate}
                            txNo={data.txNo}
                            phone={data.phone}
                            paymentAmount={data.paymentAmount}
                            unpaidAmount={data.unpaidAmount}
                            paymentStatus={data.paymentStatus}
                            rejectReasons={data.rejectReasons}
                            chargeAmount={data.chargeAmount}
                          />
                        );
                      })}
                  </tbody>
                </table>
              </div>

              {props.accountStatus.banInfos && !!props.accountStatus.banInfos.length && (
                <RestrictionModal banInfos={props.accountStatus.banInfos} />
              )}
            </div>
          )}

          {/* 이중 라우팅 */}
          <DetailsRoutes />
        </>
      )) || <Redirect to="/" />}
    </>
  );
}

export default connect(
  ({ ListReducer, AuthReducer, PaymentReducer }) => ({
    detailLists: ListReducer.detailLists,
    listsError: ListReducer.listsError,
    listLoading: ListReducer.listLoading,
    accountStatus: ListReducer.accountStatus,
    accountStatusError: ListReducer.accountStatusError,
    loginState: AuthReducer.loginState,
    logInLinkError: AuthReducer.logInLinkError,
    popupPayment: PaymentReducer.popupPayment
  }),
  (dispatch) => ({
    fetchDetailList: bindActionCreators(fetchDetailList, dispatch),
    fetchAccountStatus: bindActionCreators(fetchAccountStatus, dispatch),
    logOut: bindActionCreators(logOut, dispatch)
  })
)(Details);
