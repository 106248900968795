import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import Moment from 'react-moment';
import numeral from 'numeral';

import { ListSelect } from 'reducer/ListReducer';
import { paymentRequest, paymentRequestReset, paymentResponse } from 'reducer/PaymentReducer';
import { logOut } from 'reducer/AuthReducer';
import Radio from 'components/Input/Radio';
import toss from 'assets/images/toss_icon.png';
import payco from 'assets/images/payco_icon.png';
import Config from 'lib/Config';
import * as Api from 'lib/Api';
import 'assets/scss/contents/pay.scss';

function Pay(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [agreeBt, setAgreeBt] = useState(false);

  const { register, handleSubmit, errors, reset, setValue } = useForm({
    mode: 'onChange'
  });

  const goLink = (link) => () => {
    props.history.push(link);
  };

  const hrefLogin = () => {
    //alert('로그인이 필요합니다!');
    props.logOut();
    goLink('/')();
  };

  const chk_popup = () => {
    if (typeof props.popupPayment == 'undefined' || props.popupPayment.closed) {
      //alert('결제창이 종료되었습니다.');

      /*결제창 포인터 초기화*/
      props.paymentRequestReset();
    } else {
      setTimeout(chk_popup, 500);
    }
  };

  const onSubmit = (data) => {
    if (!Api.getCookies(Config.PVPNET_TOKEN_KEY)) {
      hrefLogin();
      return;
    }

    console.log(data.pay, props.selectList);

    /*대리결제를 어드민이 승인한 경우는 대리결제 예상 납부액 조회api에서 받아온 idx를 넘김*/
    const unpaidId =
      props.selectList.paymentStatus === 'ACCEPT' ? props.proxyPaymentBill.unpaidId : props.selectList.unpaidId;
    const method = data.pay;

    /*결제요청 액션*/
    props.paymentRequest(unpaidId, method, props.agreeState, props.history);

    setAgreeBt(true);
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    /*자식창 닫기 감지함수 실행-리덕스에 포인터 할당된경우*/
    if (props.popupPayment) {
      chk_popup();
    }
  }, [tabIndex, agreeBt, props.popupPayment]);

  useEffect(() => {
    /*자식창에서 값을받아 페이레터 결제 응답 확인api호출*/
    if (window.clientparam) {
      /*자식창에서 받아온 string을 다시 객체화해서 넘김*/
      props.paymentResponse(JSON.parse(window.clientparam), props.history);
      /*clientparam 값을 초기화시킴*/
      window.clientparam = undefined;
    }
  }, [window.clientparam]);

  useEffect(() => {
    if (window.sessionStorage.getItem('selectList')) {
      const selectList = JSON.parse(window.sessionStorage.getItem('selectList'));
      props.ListSelect(selectList);
    }
  }, []);

  //PROXY_NO_EVIDENCE: 대리결제 증빙없음일시에 최종 미납 금액(chargeAmount) !!
  //거래내역에서 가져오는 본래 가격(paymentAmount)값의 35% + 100원 미만 절사
  const saleAmount = Math.floor((props.selectList.paymentAmount * 0.35) / 100) * 100;
  const chargeAmount =
    props.agreeState === 'PROXY_NO_EVIDENCE'
      ? //단, 납부해야 하는 금액(본래 가격 x 0.35)이 미납금액(unpaidAmount) 보다 높을 경우, 미납금액(unpaidAmount)을 그대로 표시
        props.selectList.unpaidAmount > saleAmount
        ? saleAmount
        : props.selectList.unpaidAmount
      : props.selectList.chargeAmount;

  return (
    <>
      {props.paymentState ? (
        <div className="inner">
          <div className="col-cont-tit-box">
            <p className="col-cont-tit">미납 요금 납부하기</p>
            <p className="col-cont-txt">
              {<Moment format="YYYY-MM-DD">{props.selectList.txDate}</Moment>}에 휴대폰 번호 {props.selectList.phone}
              으로 결제된 {numeral(props.selectList.unpaidAmount).format('0,0')}원중에서{' '}
              {numeral(chargeAmount).format('0,0')}원이 미납된 건입니다.
              <br />
              납부를 진행하기 위해 결제수단을 선택해주세요.
            </p>
          </div>

          <div className="pay-wrap">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => {
                setTabIndex(tabIndex);
                setAgreeBt(false);
              }}
            >
              <TabList>
                {/*}
            <Tab>
              <span>신용카드</span>
            </Tab>
            {*/}
                <Tab>
                  <span>온라인이체</span>
                </Tab>
                <Tab>
                  <span>간편결제</span>
                </Tab>
                <Tab>
                  <span>상품권류</span>
                </Tab>
              </TabList>

              {/*}
          <TabPanel>
            <div className="pay-in">
              <form>
                <strong className="mgb20">결제하실 신용카드를 선택하세요.</strong>
                <div className="radio-w">
                  <Radio
                    name={'pay'}
                    number={1}
                    value={'신한카드'}
                    span={<span>신한카드</span>}
                    register={register}
                    defaultValue={true}
                  />
                  <Radio name={'pay'} number={2} value={'NH채움'} span={<span>NH채움</span>} register={register} />
                  <Radio name={'pay'} number={3} value={'현대카드'} span={<span>현대카드</span>} register={register} />
                  <Radio name={'pay'} number={4} value={'하나SK'} span={<span>하나SK</span>} register={register} />
                  <Radio name={'pay'} number={5} value={'삼성카드'} span={<span>삼성카드</span>} register={register} />
                  <Radio name={'pay'} number={6} value={'외환카드'} span={<span>외환카드</span>} register={register} />
                  <Radio name={'pay'} number={7} value={'롯데카드'} span={<span>롯데카드</span>} register={register} />
                  <Radio name={'pay'} number={8} value={'씨티카드'} span={<span>씨티카드</span>} register={register} />
                </div>

                <div className="guide">
                  ISP 결제방식의 신용카드 Mac에서 사용하실 수 없습니다.
                  <br />
                  이용요금은 '(주) 케이지이니시스'로 표시됩니다.
                  <br />
                  타인의 카드를 동의 없이 무단으로 사용하면 형사처벌 받을 수 있습니다.
                </div>

                <strong className="mgb20 mgt40">
                  상품,가격 및 유효기간을 확인하였으며, 계약 관련 고지사항과 RP정책 및 결제 진행에 동의합니다.
                </strong>
                <div className="txtc">
                  <button
                    className={cx(['col-cont-btn', 'agree'], { on: agreeBt })}
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                  >
                    동의하고 결제
                  </button>
                </div>
              </form>
            </div>
          </TabPanel>
          {*/}

              <TabPanel>
                <div className="pay-in">
                  <form>
                    <Tabs defaultIndex={0}>
                      <strong className="mgb20">결제수단</strong>
                      <TabList>
                        <Tab>
                          <Radio
                            name={'pay'}
                            number={1}
                            value={'TOSS'}
                            span={
                              <span>
                                <img src={toss} alt={'toss'} />
                              </span>
                            }
                            register={register}
                            defaultValue={true}
                          />
                        </Tab>
                        <Tab>
                          <Radio
                            name={'pay'}
                            number={2}
                            value={'BANKING'}
                            span={<span>계좌이체</span>}
                            register={register}
                          />
                        </Tab>
                        {/*}
                    <Tab>
                      <Radio
                        name={'pay'}
                        number={3}
                        value={'가상계좌'}
                        span={<span>가상계좌</span>}
                        register={register}
                      />
                    </Tab>
                    {*/}
                      </TabList>
                      <strong className="mgt30">결제관련 안내 및 주의사항</strong>

                      <TabPanel className="guide">
                        Toss 앱이 설치되어 있어야만 결제 가능합니다.
                        <br />
                        Toss서비스 이용을 위해서는 해당 서비스의 회원가입이 필요합니다.
                        <br />
                        Toss는 휴대폰에서 간편하게 송금할 수 있는 (주)비바리퍼블리카의 서비스 입니다.
                      </TabPanel>
                      <TabPanel className="guide">
                        실시간 계좌이체는 금융결제원의 BANKPAY를 이용합니다.
                        <br />
                        결제 금액은 즉시 계좌에서 이체됩니다.
                      </TabPanel>
                      {/*}
                  <TabPanel className="guide">
                    신청이 완료되면 송금할 계좌번호, 계좌주명, 유효기간이 안내됩니다.
                    <br />
                    가상계좌는 1주일간만 사용되는 임시 계좌이며,이후에는 새로 계좌번호를 받으셔야 합니다.
                  </TabPanel>
                  {*/}
                    </Tabs>
                  </form>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="pay-in">
                  <form>
                    <Tabs defaultIndex={0}>
                      <strong className="mgb20">결제수단</strong>
                      <TabList>
                        <Tab>
                          <Radio
                            name={'pay'}
                            number={1}
                            value={'PAYCO'}
                            span={
                              <span>
                                <img src={payco} alt={'payco'} />
                              </span>
                            }
                            register={register}
                            defaultValue={true}
                          />
                        </Tab>
                      </TabList>
                      <strong className="mgt30">결제관련 안내 및 주의사항</strong>

                      <TabPanel className="guide">
                        휴대폰과 카드 명의자가 동일해야 결제 가능하며, 결제금액 제한은 각 카드사 정책을 따릅니다.
                        <br />
                        사용 가능한 결제 수단은 결제화면 하단에서 확인할 수 있습니다.
                        <br />
                        PAYCO 관련 혜택은 PAYCO 결제화면 내 안내를 통해 확인해주시기 바랍니다.
                      </TabPanel>
                    </Tabs>
                  </form>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="pay-in">
                  <form>
                    <Tabs defaultIndex={0}>
                      <strong className="mgb20">결제수단</strong>
                      <TabList>
                        {/*}
                    <Tab>
                      <Radio
                        name={'pay'}
                        number={1}
                        value={'문화상품권'}
                        span={<span>문화상품권</span>}
                        register={register}
                        defaultValue={true}
                      />
                    </Tab>
                    {*/}
                        <Tab>
                          <Radio
                            name={'pay'}
                            number={2}
                            value={'TEENCASH'}
                            span={<span>틴캐시</span>}
                            register={register}
                            defaultValue={true}
                          />
                        </Tab>
                        <Tab>
                          <Radio
                            name={'pay'}
                            number={3}
                            value={'HAPPYMONEY'}
                            span={<span>해피머니상품권</span>}
                            register={register}
                          />
                        </Tab>
                        <Tab>
                          <Radio
                            name={'pay'}
                            number={4}
                            value={'BOOK'}
                            span={<span>도서문화상품권</span>}
                            register={register}
                          />
                        </Tab>
                        {/*}
                    <Tab>
                      <Radio
                        name={'pay'}
                        number={5}
                        value={'스마트문상'}
                        span={<span>스마트문상</span>}
                        register={register}
                      />
                    </Tab>
                  {*/}
                      </TabList>
                      <strong className="mgt30">결제관련 안내 및 주의사항</strong>
                      {/*}
                  <TabPanel className="guide">
                    컬쳐랜드 가입문의, 컬쳐캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.
                    <br />
                    컬쳐랜드 홈페이지 : www.cultureland.co.kr/ 고객센터 : 1577-2111
                  </TabPanel>
                  {*/}
                      <TabPanel className="guide">
                        틴캐시 가입문의, 틴캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.
                        <br />
                        틴캐시 홈페이지 : www.teencash.co.kr/ 고객센터 : 1666-3009
                      </TabPanel>
                      <TabPanel className="guide">
                        해피머니 가입문의, 해피캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인 가능합니다.
                        <br />
                        해피머니 홈페이지 : www.happymoney.co.kr/ 고객센터 : 1588-5245
                      </TabPanel>
                      <TabPanel className="guide">
                        북앤라이프 가입문의, 북앤라이프 캐시 충전, 사용내역은 홈페이지 혹은 고객센터를 통해 확인
                        가능합니다.
                        <br />
                        북앤라이프 홈페이지 : www.booknlife.com/ 고객센터 : 1544-5111
                      </TabPanel>
                      {/*}
                  <TabPanel className="guide">
                    스마트문상으로 결제 후 남은 금액은 컬쳐랜드 잔액합치기 페이지에서 잔액 통합이 가능합니다.
                    <br />
                    컬쳐랜드 홈페이지 : www.cultureland.co.kr/ 고객센터 : 1577-2111
                  </TabPanel>
                  {*/}
                    </Tabs>
                  </form>
                </div>
              </TabPanel>
            </Tabs>
          </div>

          <div className="col-cont-btn-box col-cont-btn-box-column">
            <button type="button" className="col-cont-btn type-02 on" onClick={handleSubmit(onSubmit)}>
              결제하기
            </button>
            <button type="button" className="col-cont-btn" onClick={goLink('/Details')}>
              이전으로 돌아가기
            </button>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

//export default Pay;
export default connect(
  ({ ListReducer, AuthReducer, PageStateReducer, PaymentReducer }) => ({
    selectList: ListReducer.selectList,
    loginState: AuthReducer.loginState,
    paymentState: PageStateReducer.paymentState,
    agreeState: PageStateReducer.agreeState,
    proxyPaymentBill: PaymentReducer.proxyPaymentBill,
    popupPayment: PaymentReducer.popupPayment
  }),
  (dispatch) => ({
    ListSelect: bindActionCreators(ListSelect, dispatch),
    paymentRequest: bindActionCreators(paymentRequest, dispatch),
    paymentRequestReset: bindActionCreators(paymentRequestReset, dispatch),
    paymentResponse: bindActionCreators(paymentResponse, dispatch),
    logOut: bindActionCreators(logOut, dispatch)
  })
)(Pay);
