import React from 'react';

function Etc(props) {
  return (
    <div className="col-cont-agree-box">
      <p className="col-cont-agree-tit">
        본인은 [{props.realName ? props.realName : '계정 소유자 성명'}]의 법정대리인인 [
        {props.parentName ? props.parentName : '법정대리인 성명'}]으로 미납요금과 관련하여 라이엇게임즈코리아 유한회사
        (이하 “라이엇게임즈”)가 아래의 내용과 같이 개인정보를 수집·이용하는 것에 동의합니다.
      </p>
      <div className="col-cont-agree-txt">
        <p>[개인정보 수집·이용에 관한 사항]</p>
      </div>
      <div className="col-cont-agree-txt">
        <p>• 개인정보 수집 및 이용목적</p>
        <ul>
          <li>- 휴대전화 미납 요금 납부 확인</li>
        </ul>
      </div>
      <div className="col-cont-agree-txt">
        <p>• 수집하는 개인정보 항목</p>
        <ul>
          <li>- 회원에 대해 추가 수집하는 정보 (휴대전화 번호)</li>
          <li>- 미성년자인 경우 법정대리인에 대한 정보 (법정대리인의 성명, 생년월일, 휴대전화 번호)</li>
        </ul>
      </div>
      <div className="col-cont-agree-txt">
        <p>• 개인정보의 보유 및 이용기간</p>
        <p>
          “라이엇게임즈”는 개인정보의 수집 목적이 달성되거나 관련 법령에서 정한 기간이 만료되면, 수집된 개인정보를
          파기합니다. 이때 출력된 형태의 개인정보는 파쇄 및 소각되며 전자 파일은 복구 불가능한 방법으로 파기합니다.
        </p>
      </div>
      <div className="col-cont-agree-txt">
        <p>• 개인정보의 처리 위탁</p>
        <p>“라이엇게임즈”는 수집한 개인정보를 납부확인 등을 위하여 아래와 같이 그 취급을 위탁합니다.</p>
      </div>
      <div className="col-cont-table-box">
        <table>
          <caption>
            납부내역에 관한 표이며, 결제일, 거래번호, 휴대전화번호, 미납금액, 납무금액, 상태, 납부 등으로 구성되어
            있습니다.
          </caption>
          <thead>
            <tr>
              <th scope="col">
                <div className="cont-table-txt-wrap">수탁자</div>
              </th>
              <th scope="col">
                <div className="cont-table-txt-wrap">목적</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="cont-table-txt-wrap">(주)UBASE</div>
              </td>
              <td>
                <div className="cont-table-txt-wrap">자료 확인 및 문의 상담</div>
              </td>
            </tr>
          </tbody>
        </table>
        <p>* 법정대리인의 개인정보는 (주)UBASE에 위탁되지 않습니다.</p>
      </div>
      <div className="col-cont-agree-txt">
        <p>• 동의를 거부할 권리 및 동의를 거부할 경우 불이익</p>
        <p>
          귀하는 위 사항에 대해 동의를 거부할 수 있습니다. 다만, 위 개인정보의 수집 및 이용에 관한 동의를 거부하실 경우
          요금 미납에 따른 계정 정지에 대한 해제 등의 절차를 진행하실 수 없습니다.
        </p>
      </div>
    </div>
  );
}

export default Etc;
