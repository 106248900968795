import { takeEvery, put, call, delay } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as Api from 'lib/Api';
import * as ActionAuth from 'reducer/AuthReducer';
import * as ActionList from 'reducer/ListReducer';
import * as ActionLoad from 'reducer/LoadReducer';
import * as ActionPageState from 'reducer/PageStateReducer';

function* GetDetailListSaga(action) {
  /*전체 미수금 현황 목록 조회*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  try {
    const { data } = yield call(Api.getUnpaidTransactions);
    //console.log(data);
    yield put(ActionList.ListSuccess(data));
  } catch (error) {
    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`잘못된 요청입니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }
    /*세션토큰 만료일시*/
    yield put(ActionList.ListFailure(error.response));
    console.log('!세션만료!');

    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));
  }

  yield put(ActionLoad.hideLoad());
}

function* GetAccountStatusSaga(action) {
  /*계정상태조회*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  try {
    const { data } = yield call(Api.getAccountState);
    //console.log(data);
    yield put(ActionList.AccountStatusSuccess(data));
  } catch (error) {
    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`잘못된 요청입니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }
    /*세션토큰 만료일시*/
    yield put(ActionList.AccountStatusFailure(error.response));
    console.log('!세션만료!');
    yield alert('세션이 만료되었습니다.');
    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));
  }

  yield put(ActionLoad.hideLoad());
}

export function* getDetailListLink() {
  yield takeEvery(ActionList.FETCH_DETAILLIST, GetDetailListSaga);
}

export function* getAccountStatusLink() {
  yield takeEvery(ActionList.FETCH_ACCOUNTSTATUS, GetAccountStatusSaga);
}
