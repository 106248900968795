import React from 'react';
import Popup from 'reactjs-popup';

import 'assets/scss/contents/Modal/commonModal.scss';
import 'assets/scss/contents/Modal/fileErrorModal.scss';

function FileErrorModal(props) {
  const { errorMessage, closeStateFile } = props;

  return (
    <Popup modal closeOnDocumentClick lockScroll defaultOpen className={'fileError'}>
      {(close) => (
        <div className="layer-in">
          <div className="col-cont-tit-box mgb0">
            <p className="col-cont-tit mgb0">{errorMessage}</p>
          </div>

          <button
            type="button"
            className="close"
            onClick={() => {
              closeStateFile();
              close();
            }}
          >
            닫기
          </button>
        </div>
      )}
    </Popup>
  );
}

export default FileErrorModal;
