import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';
import FormData from 'form-data';
import numeral from 'numeral';

import FileThumbs from 'components/File/FileThumbs';
import FileErrorModal from 'components/Modal/FileErrorModal';
import { ListSelect } from 'reducer/ListReducer';
import { phonePaySubmit } from 'reducer/PhonePayReducer';
import 'assets/scss/contents/File.scss';

function File(props) {
  const goLink = (link) => () => {
    props.history.push(link);
  };

  const maxSize = 30 * 1024 * 1024;

  const [files, setFiles] = useState([]);
  const [fileMax, setFileMax] = useState(false);
  const [fileType, setFileType] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    //accept: 'image/jpeg, image/png, application/pdf',
    onDrop: (acceptedFiles) => {
      acceptedFiles = [acceptedFiles[0]];
      const isFileTooLarge = acceptedFiles.length > 0 && acceptedFiles[0].size > maxSize;
      //console.log(acceptedFiles);

      if (
        !(
          acceptedFiles.length > 0 &&
          (acceptedFiles[0].type === 'image/jpeg' ||
            acceptedFiles[0].type === 'image/png' ||
            acceptedFiles[0].type === 'image/tiff' ||
            acceptedFiles[0].type === 'image/bmp' ||
            acceptedFiles[0].type === 'application/pdf')
        )
      ) {
        setFileType(true);
        setFiles([]);
        setFileMax(false);
      }

      if (isFileTooLarge) {
        setFileMax(true);
        setFiles([]);
        setFileType(false);
      } else {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
      }
    }
  });

  const deleteFile = (e) => {
    setFiles([]);
    setFileMax(false);
    setFileType(false);
    e.stopPropagation();
  };

  const closeStateFile = () => {
    setFileMax(false);
    setFileType(false);
  };

  const onSubmit = () => {
    const formdata = new FormData();
    formdata.append('unpaidId', props.selectList.unpaidId);
    formdata.append('evidence', files[0]);
    /*폼데이터 확인용
    for (var key of formdata.keys()) {
      console.log(key);
    }
    for (var value of formdata.values()) {
      console.log(value);
    }
    */
    props.phonePaySubmit(formdata, props.history);
  };

  const thumbs = files.map((file) => {
    //console.log(file);
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/tiff' ||
      file.type === 'image/bmp' ||
      file.type === 'application/pdf'
    ) {
      return <FileThumbs key={file.name} file={file} deleteFile={deleteFile} />;
    } else {
      setFiles([]);
    }
  });

  useEffect(() => {
    return () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files, fileMax, fileType]);

  useEffect(() => {
    if (window.sessionStorage.getItem('selectList')) {
      const selectList = JSON.parse(window.sessionStorage.getItem('selectList'));
      props.ListSelect(selectList);
    }
  }, []);

  return (
    <>
      {props.paymentState ? (
        <div className="inner">
          <div className="col-cont-tit-box">
            <p className="col-cont-tit">휴대폰 납부확인서 제출</p>
            <p className="col-cont-txt">
              {<Moment format="YYYY-MM-DD">{props.selectList.txDate}</Moment>}에 휴대폰 번호 {props.selectList.phone}
              으로 결제된 {numeral(props.selectList.unpaidAmount).format('0,0')}원중에서
              <br /> {numeral(props.selectList.chargeAmount).format('0,0')}원이 미납된 건입니다.
              <br />통신사로부터 납부확인서를 발급받으신 뒤에도 라이엇게임즈로 납부대금이 전달되기까지 최대 120일 정도가 소요될 수 있으며,
              <br />납부대금이 모두 정산된 경우에만 계정 잠금을 해제하실 수 있습니다.
            </p>
          </div>

          <div className={cx(['dropzone-wrap'], { on: files.length })}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className="normal">
                {!files.length && !fileType && !fileMax && (
                  <div>
                    <i>아이콘</i>
                  </div>
                )}
                {!files.length && fileType && (
                  /*파일형식틀릴 경우*/
                  <div>
                    <i>아이콘</i>
                  </div>
                )}
                {!files.length && fileMax && (
                  /*파일용량 클경우*/
                  <div>
                    <i>아이콘</i>
                  </div>
                )}
                {!isDragReject && !isDragAccept && (
                  <aside>
                    <ul>{thumbs}</ul>
                  </aside>
                )}
                <p>
                  여기를 클릭하거나 드래그하여 납부확인서를 등록해주세요.
                  <br />
                  (30mb 이하의 PDF, JPG, PNG, TIFF, BMP 등록 가능)
                </p>
                <p>
                  휴대전화 번호가 일치하지 않거나, 납부 여부를 확인할 수 없는 증빙이 첨부된 경우는 반려될 수 있습니다.
                  <br />
                  또한, 위조된 납부증명서를 제출 시 통신사 요금 수납 확인 이후 다시 계정잠금 조치 될 수 있습니다.
                </p>
              </div>
            </div>
          </div>

          <div className="col-cont-btn-box col-cont-btn-box-column">
            {files.length ? (
              <button type="button" href="#" className="col-cont-btn type-02 on" onClick={onSubmit}>
                제출
              </button>
            ) : (
              <button type="button" href="#" className="col-cont-btn type-02">
                제출
              </button>
            )}
            <button type="button" className="col-cont-btn" onClick={goLink('/Details')}>
              이전으로 돌아가기
            </button>
          </div>

          {!files.length && fileType && (
            <FileErrorModal errorMessage={'지원하지않는 형식입니다.'} closeStateFile={closeStateFile} />
          )}
          {!files.length && fileMax && (
            <FileErrorModal errorMessage={'용량이 너무 큽니다.'} closeStateFile={closeStateFile} />
          )}
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

//export default File;
export default connect(
  ({ ListReducer, AuthReducer, PageStateReducer }) => ({
    selectList: ListReducer.selectList,
    loginState: AuthReducer.loginState,
    paymentState: PageStateReducer.paymentState
  }),
  (dispatch) => ({
    ListSelect: bindActionCreators(ListSelect, dispatch),
    phonePaySubmit: bindActionCreators(phonePaySubmit, dispatch)
  })
)(File);
