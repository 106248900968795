import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

function Submit(props) {
  const goLink = (link) => () => {
    props.history.push(link);
  };

  return (
    <div className="inner ty2">
      <div className="col-cont-tit-box mgb0">
        <div>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="40" fill="#D03739" />
            <path
              d="M19.9111 38.9488L36.4778 54.7002L60.3167 26.9301"
              stroke="#F8F8F8"
              strokeWidth="8"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        {(props.submitState === 'PhonePayReducer/PHONEPAY_SUBMIT' && (
          <>
            <p className="col-cont-tit mgt25">제출완료!</p>
            <p className="col-cont-txt mgt35">
              휴대폰 납부확인서 제출이 완료되었습니다.
              <br />
              서류 검토가 끝나는대로 계정에 등록되어 있는 이메일로 안내해드릴 예정입니다.
            </p>
          </>
        )) ||
          (props.submitState === 'ProxyPayReducer/PROXYPAY_SUBMIT' && (
            <>
              <p className="col-cont-tit mgt25">제출이 완료되었습니다.</p>
              <p className="col-cont-txt mgt35">
                귀하 계정의 미납 내역 중 거래번호 {props.completedTxNo}에 대하여, 모든 증빙자료 및 서류제출이 <br />
                완료 되었습니다. 라이엇 게임즈에서 해당 내용을 검토하여 잘못된 첨부파일 혹은 <br />
                고의적으로 조작/위조된 사항이 없을 경우, 미납금액을 납부할 수 있도록 확인해드릴 <br />
                예정입니다.
              </p>
              <p className="col-cont-tit type-small mgt35">
                서류 검토가 끝나는대로 계정에 등록되어 있는 이메일로 안내해드릴 예정입니다.
              </p>
            </>
          )) || <Redirect to="/" />}

        <div className="col-cont-btn-box col-cont-btn-box-column mgt35">
          <button type="button" className="col-cont-btn" onClick={goLink('/Details')}>
            이전으로 돌아가기
          </button>
        </div>
      </div>
    </div>
  );
}

//export default Submit;
export default connect(({ PageStateReducer, ProxyPayReducer }) => ({
  submitState: PageStateReducer.submitState,
  completedTxNo: ProxyPayReducer.completedTxNo
}))(Submit);
