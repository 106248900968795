import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import FormData from 'form-data';
import numeral from 'numeral';

import { ListSelect } from 'reducer/ListReducer';
import { proxyPaySubmit } from 'reducer/ProxyPayReducer';
import Text from 'components/Input/Text';
import Checkbox from 'components/Input/Checkbox';
import FieldArray from 'components/Input/FieldArray';
import TextDate from 'components/Input/TextDate';
import FileInput from 'components/Input/FileInput';
import ChangeInput from 'components/Input/ChangeInput';
import FileMultipleInput from 'components/Input/FileMultipleInput';
import 'assets/scss/contents/delegate.scss';

function Delegate(props) {
  //console.log(props.selectList);
  const { userName } = props.accountStatus;

  const { register, handleSubmit, reset, setValue, getValues, control, errors } = useForm({
    mode: 'onChange'
  });

  const goLink = (link) => () => {
    props.history.push(link);
  };

  const onSubmit = (data) => {
    const sns1 = data.proxyCompanySns0 ? `${data.proxyCompanySns0.value}|${data.proxyCompanySnsId0}` : '';
    const sns2 = data.proxyCompanySns1 ? `;${data.proxyCompanySns1.value}|${data.proxyCompanySnsId1}` : '';
    const sns3 = data.proxyCompanySns2 ? `;${data.proxyCompanySns2.value}|${data.proxyCompanySnsId2}` : '';
    const sns = sns1 + sns2 + sns3;

    const date = `${data.remittanceDateY}-${data.remittanceDateM}-${data.remittanceDateD}`;

    const pays =
      data.firstDepthPrice.value === 'BANKING' || data.firstDepthPrice.value === 'OTHER'
        ? data.firstDepthPrice.value
        : data.secondDepthPrice.value;

    /*
    const payLoad = {
      unpaidId: props.selectList.unpaidId, //거래번호 (미납건 key)
      userName: data.userName,
      realName: data.realName,
      phone: data.phone,
      proxyRealName: data.proxyRealName,
      proxyCompanyEmail: data.proxyCompanyEmail,
      proxyCompanyPhone: data.proxyCompanyPhone,
      proxyCompanySns: sns,
      conversationFile: data.conversationFile[0],
      conversationFile1: data.conversationFile1[0],
      conversationFile2: data.conversationFile2[0],
      conversationFile3: data.conversationFile3[0],
      conversationFile4: data.conversationFile4[0],
      proxyCompanyPaymentType: pays, //대리결제 방법
      proxyCompanyBankOwnerName: data.proxyCompanyBankOwnerName,
      proxyCompanyBankName: data.proxyCompanyBankName,
      proxyCompanyBankAccountNo: data.proxyCompanyBankAccountNo,
      remittanceDate: date,
      remittance: numeral(data.remittance).value(),
      remittanceFile: data.remittanceFile[0],
      remittanceFile1: data.remittanceFile1[0],
      remittanceFile2: data.remittanceFile2[0],
      remittanceFile3: data.remittanceFile3[0],
      remittanceFile4: data.remittanceFile4[0]
    };
    console.log(payLoad);
    */

    const formdata = new FormData();
    formdata.append('unpaidId', props.selectList.unpaidId);
    formdata.append('userName', data.userName);
    formdata.append('realName', data.realName);
    formdata.append('phone', data.phone);
    formdata.append('proxyRealName', data.proxyRealName);
    formdata.append('proxyCompanyEmail', data.proxyCompanyEmail);
    formdata.append('proxyCompanyPhone', data.proxyCompanyPhone);
    formdata.append('proxyCompanySns', sns);
    formdata.append('conversationFile', data.conversationFile[0]);
    formdata.append('conversationFile1', data.conversationFile1[0]);
    formdata.append('conversationFile2', data.conversationFile2[0]);
    formdata.append('conversationFile3', data.conversationFile3[0]);
    formdata.append('conversationFile4', data.conversationFile4[0]);
    formdata.append('proxyCompanyPaymentType', pays);
    formdata.append('proxyCompanyBankOwnerName', data.proxyCompanyBankOwnerName);
    formdata.append('proxyCompanyBankName', data.proxyCompanyBankName);
    formdata.append('proxyCompanyBankAccountNo', data.proxyCompanyBankAccountNo);
    formdata.append('remittanceDate', date);
    formdata.append('remittance', numeral(data.remittance).value());
    formdata.append('remittanceFile', data.remittanceFile[0]);
    formdata.append('remittanceFile1', data.remittanceFile1[0]);
    formdata.append('remittanceFile2', data.remittanceFile2[0]);
    formdata.append('remittanceFile3', data.remittanceFile3[0]);
    formdata.append('remittanceFile4', data.remittanceFile4[0]);

    /*폼데이터 확인용
    for (var key of formdata.keys()) {
      console.log(key);
    }
    for (var value of formdata.values()) {
      console.log(value);
    }*/

    props.proxyPaySubmit(formdata, props.history);
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (window.sessionStorage.getItem('selectList')) {
      const selectList = JSON.parse(window.sessionStorage.getItem('selectList'));
      props.ListSelect(selectList);
    }
  }, []);

  return (
    <>
      {props.paymentState ? (
        <div className="inner">
          <div className="delegate-wrap">
            <div className="col-cont-tit-box">
              <p className="col-cont-tit">대리결제 관련 권리 양도 및 통지권한 위임</p>
              <p className="col-cont-agree-txt mgt30 txtl">
                귀하는 라이엇게임즈코리아 유한회사 (이하 “라이엇게임즈”)가 제공하는 게임인 리그 오브 레전드 (이하
                “게임”)를 이용하는 중 “게임”내 아이템을 구매하는 데 필요한 재화인 라이엇 포인트 (이하 “RP”)를 귀하
                본인이 아닌 제3자에게 대신 결제 하도록 의뢰하여 결제된 것(이하 “대리결제”)과 관련하여 발생하였거나 장래
                발생할 채무불이행, 불법행위 기타 원인에 따른 손해배상채권 또는 부당이득반환청구권 등 일체의 권리를
                “라이엇게임즈”에게 양도하고 이에 필요한 통지권한을 “라이엇게임즈”에 위임하는 것에 대하여 다음과 같이
                약정합니다.
              </p>

              <p className="col-cont-agree-tit mgt50">
                <span>주의사항</span>
              </p>
              <p className="col-cont-agree-tit mgt10">
                • 본 권리 양도 및 통지권한 위임과 관련하여 귀하가 입력하는 정보는 사실에 부합하고 진실한 내용이어야
                합니다.
                <br />• 귀하가 사실과 다른 허위의 정보를 입력하거나 제출하는 것은 업무방해죄 등의 형사책임 및 민사책임을
                발생시킬 수 있으며, 허위정보 입력이 확인되는 경우 “라이엇게임즈”는 귀하의 계정을 다시 정지시킬 수
                있습니다.
              </p>
            </div>

            <form>
              {/*1. 귀하의 정보*/}
              <div className="delegate-in">
                <h3>1. 귀하의 정보</h3>
                <ul>
                  <li className="ty2">
                    <div className="input-w">
                      <strong>라이엇 계정</strong>
                      <Text
                        name={'userName'}
                        label={'아이디'}
                        register={register({
                          required: '값을 입력해 주세요.'
                        })}
                        errors={errors}
                        defaultValue={userName}
                        readOnly={true}
                      />
                    </div>
                    <div className="input-w">
                      <strong>성명</strong>
                      <Text
                        name={'realName'}
                        label={'성명'}
                        register={register({
                          required: '값을 입력해 주세요.'
                        })}
                        errors={errors}
                      />
                    </div>
                  </li>
                  <li className="ty2">
                    <div className="input-w">
                      <strong>휴대전화번호 (숫자만 입력)</strong>
                      <Text
                        name={'phone'}
                        label={'전화번호'}
                        register={register({
                          required: '값을 입력해 주세요.',
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: '숫자가 아닙니다.'
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                  </li>
                </ul>
              </div>

              {/*2. “대리결제"로 인한 미납 내역 확인 및 증명자료 제출*/}
              <div className="delegate-in">
                <h3>2. “대리결제"로 인한 미납 내역 확인 및 증명자료 제출</h3>
                <ul>
                  <li>
                    <div className="guide">
                      귀하 계정의 미납 내역 중 거래번호 {props.selectList.txNo}에 대하여, 아래의 정보입력 및 증빙자료를
                      제출해주시기 바랍니다.{' '}
                    </div>
                  </li>
                </ul>
              </div>

              {/*2-1. “대리결제"를 약속(계약)한 것에 대한 정보*/}
              <div className="delegate-in">
                <h3>2-1. “대리결제"를 약속(계약)한 것에 대한 정보</h3>

                <ul>
                  <li>
                    <h4>가. “대리결제”해줄 것을 약속한 상대방에 대한 정보</h4>
                    <div className="guide">RP 충전을 맡긴 업체 혹은 개인에 대한 정보를 제출하여 주십시오.</div>
                  </li>
                  <li className="ty2">
                    <div className="input-w">
                      <strong>“대리결제”해줄 것을 약속한 상대방의 업체명 혹은 성명</strong>
                      <Text
                        name={'proxyRealName'}
                        label={'성명'}
                        register={register({
                          required: '값을 입력해 주세요.'
                        })}
                        errors={errors}
                      />
                    </div>
                  </li>
                  <li className="ty2">
                    <div className="input-w">
                      <strong>“대리결제”해줄 것을 약속한 상대방의 이메일</strong>
                      <Text
                        name={'proxyCompanyEmail'}
                        label={'이메일'}
                        register={register({
                          required: '값을 입력해 주세요.',
                          pattern: {
                            value: /^(([^<>()\x5b\x5d\\.,;:\s@"]+(\.[^<>()\x5b\x5d\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: '메일 주소의 형식이 올바르지 않습니다.'
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                  </li>
                  <li className="ty2">
                    <div className="input-w">
                      <strong>“대리결제”해줄 것을 약속한 상대방의 전화번호 (선택)</strong>
                      <Text
                        name={'proxyCompanyPhone'}
                        label={'전화번호 (선택)'}
                        register={register({
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: '숫자가 아닙니다.'
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="input-box">
                      <strong>“대리결제”해줄 것을 약속한 상대방의 아이디 (최대 3개까지)</strong>
                      <FieldArray {...{ control, register, getValues, errors }} />
                    </div>
                  </li>
                  <li>
                    <h4>나. “대리결제”해줄 것에 대한 약속(계약)한 사실을 입증할 수 있는 자료</h4>
                    <div className="guide">
                      RP 충전을 맡겼던 업체 또는 상대방에게 입금한 내용, 충전을 의뢰한 대화내용, 상대방이 충전을 대행해
                      줄것에 동의한 대화내용이 필요합니다.
                    </div>
                    <strong className="mgt15">
                      • 귀하가 “대리결제”를 요청하고 상대방이 승낙한 내용이 포함된 메시지의 캡쳐본
                    </strong>
                    <div className="guide">
                      가능한 범위 내에서 상대방의 이름, 메시지를 주고받은 날짜, 상대방의 계좌번호, 상대방 계좌의 예금주
                      이름이 모두 포함되도록 캡쳐하여 주시기 바랍니다.카카오톡, 문자(SMS) 또는 중고거래 사이트 내 메신저
                      등 대화를 주고받은 내역을 입증할 수 있는 자료를 등록해주시기 바랍니다.{' '}
                    </div>
                    <FileMultipleInput
                      name={'conversationFile'}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                    />
                    {/* 파일하나만 받는경우}
                    <FileInput
                      name={'conversationFile'}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                    />
                    {*/}
                  </li>
                </ul>
              </div>

              {/*2-2. “대리결제”를 약속(계약)하고 상대방에게 대금을 지급한 것에 대한 정보*/}
              <div className="delegate-in">
                <h3>2-2. “대리결제”를 약속(계약)하고 상대방에게 대금을 지급한 것에 대한 정보</h3>
                <div className="guide">
                  RP 충전을 맡긴 뒤에 실제로 대금을 보내거나, 신용카드/휴대폰/문화상품권 등으로 결제한 증빙을 제출하여
                  주십시오.
                </div>
                <ul>
                  <li>
                    <div className="input-w">
                      <strong>상대방의 계좌 소유자명</strong>
                      <Text name={'proxyCompanyBankOwnerName'} label={'성명'} register={register} errors={errors} />
                    </div>
                  </li>
                  <li>
                    <div className="input-w" style={{ width: '100%' }}>
                      <strong>결제방식</strong>
                      <ChangeInput register={register} errors={errors} control={control} />
                    </div>
                  </li>
                  <li>
                    <div className="input-w">
                      <strong>결제일</strong>

                      <TextDate name={'remittanceDate'} register={register} errors={errors} />
                    </div>
                  </li>
                  <li>
                    <div className="input-w">
                      <strong>결제액 (원단위로 입력)</strong>
                      <Text
                        name={'remittance'}
                        label={'결제액'}
                        hanleChangeP={(e) => {
                          /*, 제거한후 숫자비교-통과시 numeral호출*/
                          if (/^[0-9\b]+$/.test(e.target.value.replace(/,/gi, ''))) {
                            setValue('remittance', numeral(e.target.value).format('0,0'));
                          }
                          //console.log(getValues('remittance'));
                        }}
                        register={register({
                          required: '값을 입력해 주세요.',
                          validate: {
                            numberChk: (value) => {
                              const numberChk = /^[0-9\b]+$/.test(value.replace(/,/gi, ''));
                              //console.log(value, numberChk);
                              return numberChk || '숫자가 아닙니다.';
                            }
                          }
                        })}
                        errors={errors}
                      />
                    </div>
                  </li>
                  <li>
                    <strong>상기 정보를 입증할 수 있는 자료</strong>
                    <div className="guide">
                      무통장 입금/계좌이체를 진행한 경우 이체확인증을 제출하여 주시기 바랍니다.
                      <br />
                      휴대폰 소액결제로 진행한 경우 휴대폰 결제내역 (또는 영수증)을 캡쳐하여 제출하여 주시기 바랍니다.
                      <br />
                      신용카드로 결제한 경우, 신용카드 전표을 캡쳐하여 제출하여 주시기 바랍니다. <br />
                      (카드사 고객센터에 전화하여 발급 또는 카드사 고객센터 웹사이트 이용)
                      <br />위 항목 중 어디에도 해당하지 않는 경우, 대금지급 사실을 입증할 수 있는 증빙을 제출하여
                      주시기 바랍니다.
                    </div>

                    <FileMultipleInput
                      name={'remittanceFile'}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                    />

                    {/*파일하나만 받는경우}
                    <FileInput
                      name={'remittanceFile'}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                    />
                    {*/}
                  </li>
                </ul>
              </div>

              {/*3. 자료 제출을 위한 최종 확인*/}
              <div className="delegate-in">
                <h3>3. 자료 제출을 위한 최종 확인</h3>
                <ul>
                  <li>
                    <Checkbox
                      name={'agree1'}
                      span={
                        <span>
                          본인은 상기 입력한 정보 및 자료가 사실에 부합하고 진실한 내용이라는 점과, 허위의 정보나 자료가
                          있는 경우 이에 대한 민형사상의 책임을 부담하며 “라이엇게임즈”가 본인의 계정을 다시 정지시킬 수
                          있음을 이해하고 이에 동의합니다.
                        </span>
                      }
                      register={register({ required: true })}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name={'agree2'}
                      span={
                        <span>
                          본인은 상기 입력한 정보 및 자료와 관련한 “대리결제”와 관련하여 본인에게 발생하였거나 장래
                          발생할 채무불이행, 불법행위, 기타 원인에 따른 손해배상채권 또는 부당이득반환청구권 등 일체의
                          권리를 “라이엇게임즈”에 양도합니다.
                        </span>
                      }
                      register={register({ required: true })}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name={'agree3'}
                      span={
                        <span>본인은 “라이엇게임즈”에게 위 권리양도 사실 통지에 관한 일체의 권한을 위임합니다.</span>
                      }
                      register={register({ required: true })}
                    />
                  </li>
                  <li>
                    <Checkbox
                      name={'agree4'}
                      span={
                        <span>
                          본인은 이러한 양도 및 권한 위임의 의사표시가 본인의 진실한 의사에 의한 것으로 추후 이러한
                          양도에 대하여 이의를 제기하지 아니하며, “라이엇게임즈”가 본인으로부터 양도받은 채권을 관련
                          소송을 통하여 회수할 경우 이에 대하여 어떠한 권리도 주장하지 아니할 것을 확인합니다.
                        </span>
                      }
                      register={register({ required: true })}
                    />
                  </li>
                </ul>
                {(errors.agree1 || errors.agree2 || errors.agree3 || errors.agree4) && (
                  <p className="error txtc">자료 제출을 위한 최종 확인 모두 동의해 주세요</p>
                )}
              </div>
            </form>

            <div className="col-cont-btn-box col-cont-btn-box-column mgt40">
              <button type="button" className="col-cont-btn type-02 on" onClick={handleSubmit(onSubmit)}>
                계속하기
              </button>
              <button type="button" className="col-cont-btn" onClick={goLink('/Details/Transaction')}>
                이전으로 돌아가기
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

//export default Delegate;
export default connect(
  ({ ListReducer, AuthReducer, PageStateReducer }) => ({
    selectList: ListReducer.selectList,
    loginState: AuthReducer.loginState,
    accountStatus: ListReducer.accountStatus,
    paymentState: PageStateReducer.paymentState
  }),
  (dispatch) => ({
    ListSelect: bindActionCreators(ListSelect, dispatch),
    proxyPaySubmit: bindActionCreators(proxyPaySubmit, dispatch)
  })
)(Delegate);
