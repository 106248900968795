import React from 'react';
import { connect } from 'react-redux';

import HomeToggle from 'container/Home/HomeToggle';
import 'assets/scss/contents/home.scss';

const $ = window.$;

function Home(props) {
  const toggle = [
    {
      question: <>제가 결제한 것 중에 어떤게 미납인지 알 수 있나요?</>,
      answer: <>미납된 결제내역에 대해서는 로그인 후 세부내역 확인 버튼을 클릭하시면 확인하실 수 있습니다.</>,
      id: 1
    },
    {
      question: <>타인의 명의로 된 휴대전화로 결제하였는데, 해당 휴대전화의 요금이 미납한 건은 어떻게 납부를 하나요?</>,
      answer: <>미납된 금액에 대한 납부 방법은 로그인하신 뒤 각 항목을 선택하시면 납부하실 수 있습니다.</>,
      id: 2
    },
    {
      question: <>저는 결제할 때 이미 다른 사람한테 돈을 줬는데 왜 저한테 돈을 내라고 하나요?</>,
      answer: (
        <>
          라이엇게임즈가 공식적으로 제공해드리고 있는 결제 서비스 외 제3자에 결제한 부분에 대해서는 별도의 도움을 드리기
          어려운 점 양지해주시기 바랍니다. 상세한 내용은 아래 서비스 약관 및 RP정책을 참고해주시기 바랍니다.
        </>
      ),
      id: 3
    },
    {
      question: <>요금을 납부했는데 게임을 할 수 없어요.</>,
      answer: (
        <>
          대리 충전 업체를 통해 RP를 충전하신 경우 운영정책 4조-④에 의거하여 게임 이용이 7일간 제한됩니다. 또한, 대금
          미납과 무관한 이용제한이 적용되어 있는 계정은 대금을 납부하더라도 해당 이용제한이 해제되지 않습니다.
        </>
      ),
      id: 4
    },
    {
      question: <>실수로 안내 이메일을 삭제한 것 같습니다.</>,
      answer: <>이메일은 재발송이 어려워 궁금하신 내용에 대해서는 홈페이지 고객지원을 통해 문의해주시기 바랍니다.</>,
      id: 5
    },
    {
      question: <>필요한 서류를 제출하였는데, 안내 이메일이 도착하지 않습니다.</>,
      answer: (
        <>
          홈페이지 로그인 후 내 정보 - 회원 정보 변경을 통해 라이엇 계정에 등록된 이메일 주소를 확인할 수 있으며, 해당
          이메일 수신 내역을 확인해보시기 바랍니다. 간혹 광고성 메일 수신함으로 분류되는 경우가 있으니 해당 수신함 역시
          확인을 부탁 드립니다.
        </>
      ),
      id: 6
    }
  ];

  const goLink = (link) => () => {
    props.history.push(link);
  };

  const handleLogin = () => {
    const loginDom = $("[data-riotbar-link-id='login']");
    if (loginDom.length > 0) {
      loginDom.get(0).click();
    }
  };

  return (
    <div className="home-Wrap">
      {props.logInLinkError ? (
        <>
          <div className="home-Top">
            <h2>라이엇 게임즈 미납내역 안내</h2>
            <div className="home-none">
              <strong>미납 요금이 없습니다.</strong>
              <p>미납 요금으로 인한 계정잠금 대상자가 아닙니다.</p>
            </div>
            <button className="col-cont-btn type-02" type="button">
              로그인
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="home-Top">
            {props.loginState ? (
              <>
                <h2>라이엇 게임즈 미납내역 안내</h2>
                <strong>
                  미납된 결제내역에 대해서는 로그인 후 세부내역 확인 버튼을 클릭하시면 확인하실 수 있습니다.
                </strong>
                <p>
                  플레이어 여러분께서 라이엇 게임즈의 서비스에서 사용할 재화를 구입하기 위해 사용한 결제수단의 요금이
                  미납된 경우, 서비스 약관 및 운영정책에 따라 계정이 잠기게 됩니다.
                  <br />
                  정상적으로 결제하신 경우 미납된 금액을 납부하시면 계정 잠금이 해제되어 정상적인 플레이가 가능하지만,
                  대리 충전 업체를 통해 결제를 진행하셨다면, 미납요금 납부 후 7일의 이용제한 조치가 발생합니다.
                  <br />
                  또한 계정 잠금 이전에 다른 사유로 게임 이용제한 조치 중이었다면, 미납요금을 납부하시더라도 게임
                  이용제한이 해제되지 않습니다.
                </p>
                <button className="col-cont-btn type-02 on" type="button" onClick={goLink('/Details')}>
                  세부내역 확인
                </button>
              </>
            ) : (
              <>
                <h2>라이엇 게임즈 미납내역 안내</h2>
                <strong>
                  미납된 결제내역에 대해서는 로그인 후 세부내역 확인 버튼을 클릭하시면 확인하실 수 있습니다.
                </strong>
                <p>
                  플레이어 여러분께서 라이엇 게임즈의 서비스에서 사용할 재화를 구입하기 위해 사용한 결제수단의 요금이
                  미납된 경우, 서비스 약관 및 운영정책에 따라 계정이 잠기게 됩니다.
                  <br />
                  정상적으로 결제하신 경우 미납된 금액을 납부하시면 계정 잠금이 해제되어 정상적인 플레이가 가능하지만,
                  대리 충전 업체를 통해 결제를 진행하셨다면, 미납요금 납부 후 7일의 이용제한 조치가 발생합니다.
                  <br />
                  또한 계정 잠금 이전에 다른 사유로 게임 이용제한 조치 중이었다면, 미납요금을 납부하시더라도 게임
                  이용제한이 해제되지 않습니다.
                </p>
                <button className="col-cont-btn type-02 on" type="button" id="login_bt" onClick={handleLogin}>
                  로그인
                </button>
              </>
            )}
          </div>
          <div className="home-list">
            <h3>자주묻는 질문</h3>
            <ul>{<HomeToggle toggle={toggle} />}</ul>
            <a
              href="https://support-leagueoflegends.riotgames.com/hc/ko/articles/360051294673"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="all" type="button">
                자주 묻는 질문 더보기
              </button>
            </a>
          </div>
        </>
      )}
    </div>
  );
}

export default connect(({ AuthReducer }) => ({
  loginState: AuthReducer.loginState,
  logInLinkError: AuthReducer.logInLinkError
}))(Home);
