import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import Moment from 'react-moment';
import numeral from 'numeral';

import DetailListRowStatus from 'components/List/DetailListRowStatus';
import { paymentState } from 'reducer/PageStateReducer';
import { ListSelect } from 'reducer/ListReducer';
import { proxyPayment } from 'reducer/PaymentReducer';

const DetailListRows = (props) => {
  const {
    history,
    unpaidId,
    txDate,
    txNo,
    phone,
    paymentAmount,
    unpaidAmount,
    paymentStatus,
    rejectReasons,
    chargeAmount
  } = props;
  const goLink = (link) => () => {
    history.push(link);
  };

  const saveCurrentState = (e) => {
    const selectList = {
      unpaidId,
      txDate,
      txNo,
      phone,
      paymentAmount,
      unpaidAmount,
      paymentStatus,
      rejectReasons,
      chargeAmount
    };
    window.sessionStorage.setItem('selectList', JSON.stringify(selectList));
    //props.ListSelect(selectList);

    /*버튼 클릭시 결제상태로*/
    props.paymentState(true);
    if (paymentStatus === 'ACCEPT') {
      /*대리결제를 어드민이 승인한 경우*/
      props.proxyPayment(unpaidId, props.history);
    } else {
      goLink('/Details/Transaction')();
    }
  };

  return (
    <tr>
      <td>
        <div className="cont-table-txt-wrap">
          <Moment format="YYYY-MM-DD">{txDate}</Moment>
        </div>
      </td>
      <td>
        <div className="cont-table-txt-wrap">{txNo}</div>
      </td>
      <td>
        <div className="cont-table-txt-wrap">{phone}</div>
      </td>
      <td>
        <div className="cont-table-txt-wrap">{numeral(unpaidAmount).format('0,0')}원</div>
      </td>
      <td>
        <div className="cont-table-txt-wrap">{numeral(chargeAmount).format('0,0')}원</div>
      </td>
      <td>{<DetailListRowStatus paymentStatus={paymentStatus} rejectReasons={rejectReasons} />}</td>
      <td>
        <div className="cont-table-txt-wrap">
          <button
            type="button"
            onClick={saveCurrentState}
            className={cx(['col-cont-btn', 'type-02'], {
              on: paymentStatus === 'NEW' || paymentStatus === 'REJECT' || paymentStatus === 'ACCEPT'
            })}
          >
            납부하기
          </button>
        </div>
      </td>
    </tr>
  );
};

export default connect(
  ({ AuthReducer }) => ({
    loginState: AuthReducer.loginState
  }),
  (dispatch) => ({
    ListSelect: bindActionCreators(ListSelect, dispatch),
    paymentState: bindActionCreators(paymentState, dispatch),
    proxyPayment: bindActionCreators(proxyPayment, dispatch)
  })
)(DetailListRows);
