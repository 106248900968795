import React, { useEffect } from 'react';
import DetailListRowTooltip from 'components/List/DetailListRowTooltip';

const DetailListRowStatus = (props) => {
  const { paymentStatus, rejectReasons } = props;

  return (
    (paymentStatus === 'COMPLETE' && (
      <div className="cont-table-txt-wrap">
        <span>납부완료</span>
      </div>
    )) ||
    (paymentStatus === 'NEW' && (
      <div className="cont-table-txt-wrap">
        <span>미납</span>
      </div>
    )) ||
    (paymentStatus === 'REVIEWING' && (
      <div className="cont-table-txt-wrap">
        <span>담당자 검토 중</span>
      </div>
    )) ||
    (paymentStatus === 'ACCEPT' && (
      <div className="cont-table-txt-wrap">
        <span>승인</span>
      </div>
    )) ||
    (paymentStatus === 'PAID' && (
      <div className="cont-table-txt-wrap">
        <span>납부완료</span>
      </div>
    )) ||
    (paymentStatus === 'REJECT' && (
      <div className="cont-table-txt-wrap error">
        <span>반려사유</span>
        <i className="cont-table-icon-hover">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.7218" y="8.5" width="11" height="11" rx="2" transform="rotate(-45 1.7218 8.5)" fill="#D03739" />
            <path
              d="M9.32227 10.371L9.36627 9.73851C10.8953 9.49651 11.7423 8.68251 11.7423 7.41201C11.7423 6.02051 10.6313 5.13501 8.71177 5.13501C8.33777 5.13501 7.90877 5.16251 7.60077 5.20651L7.70527 6.65301C7.93627 6.63101 8.24427 6.60901 8.54127 6.60901C9.59177 6.60901 10.1583 6.92801 10.1583 7.52201C10.1583 8.16551 9.58627 8.50101 8.52477 8.50101H7.99677V10.371H9.32227ZM8.66227 11.042C8.10127 11.042 7.61177 11.5205 7.61177 12.087C7.61177 12.648 8.10127 13.1375 8.66227 13.1375C9.21777 13.1375 9.70177 12.648 9.70177 12.0815C9.70177 11.5205 9.21777 11.042 8.66227 11.042Z"
              fill="white"
            />
          </svg>
          <div className="tooltip_hover">
            {rejectReasons.map((data, index) => (
              <DetailListRowTooltip key={index} data={data} />
            ))}
          </div>
        </i>
      </div>
    ))
  );
};

export default DetailListRowStatus;
