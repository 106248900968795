import React from "react";
import Layout from "container/Layout/Layout"; 

import "assets/scss/reset.scss";
import "assets/scss/style.scss";
import "assets/scss/contents.scss";

function App() {
    return (
        <Layout />
    );
}

export default App;
