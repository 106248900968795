import React, { useEffect, useState } from 'react';

import Text from 'components/Input/Text';
import SelectBox from 'components/Input/SelectBox';
import 'assets/scss/contents/Input/changeInput.scss';

function ChangeInput({ register, errors, control }) {
  const [firstDepth, setFirstDepth] = useState('BANKING');

  useEffect(() => {
    //console.log(firstDepth);
  }, [firstDepth]);

  return (
    <div className="change-input">
      <SelectBox
        name={'firstDepthPrice'}
        options={[
          { value: 'BANKING', label: '무통장입금/계좌이체' },
          { value: 'MOBILE/CARD', label: '휴대폰/신용카드 결제' },
          { value: 'OTHER', label: '기타' }
        ]}
        control={control}
        onChange={([selected]) => {
          // React Select return object instead of value for selection
          //console.log(selected);
          setFirstDepth(selected.value);
          return { value: selected.value, label: selected.label };
        }}
        defaultValue={{ value: 'BANKING', label: '무통장입금/계좌이체' }}
      />

      {(firstDepth === 'BANKING' && (
        <div className="banking">
          <Text name={'proxyCompanyBankName'} label={'금융사 이름'} register={register} errors={errors} />
          <Text
            name={'proxyCompanyBankAccountNo'}
            label={'계좌번호'}
            register={register({
              pattern: {
                value: /^[0-9\b]+$/,
                message: '숫자가 아닙니다.'
              }
            })}
            errors={errors}
          />
        </div>
      )) ||
        (firstDepth === 'MOBILE/CARD' && (
          <div className="mobile-card">
            <SelectBox
              name={'secondDepthPrice'}
              options={[
                { value: 'MOBILE', label: '휴대폰' },
                { value: 'CARD', label: '신용카드' }
              ]}
              control={control}
              onChange={([selected]) => {
                // React Select return object instead of value for selection
                //console.log(selected);
                return { value: selected.value, label: selected.label };
              }}
              defaultValue={{ value: 'MOBILE', label: '휴대폰' }}
            />
          </div>
        )) ||
        (firstDepth === 'OTHER' && <div className="other"></div>)}
    </div>
  );
}

export default ChangeInput;
