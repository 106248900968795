import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

function PayReturn(props) {
  const clientparam = queryString.parse(props.location.search);

  const css = `
    body {
        display:none;
    }
  `;

  const sendChildValue = () => {
    //IE11
    if (!window.opener) window.opener = window.open('', 'mainwin');

    //부모창에서 자식창에 값 전달
    try {
      /*ie는 객체형식으로 부모창에 데이터 전달이 안되서 문자열로 변경*/
      window.opener.clientparam = JSON.stringify(clientparam);
      window.close();
    } catch (e) {
      // 부모 자식간의 연결이 끊어졌을 경우 처리
      alert('팝업창을 다시 열어주세요');
    }
  };

  useEffect(() => {
    sendChildValue();
  }, []);

  return (
    <>
      <style>{css}</style>
    </>
  );
}

export default connect(({ PaymentReducer }) => ({
  popupPayment: PaymentReducer.popupPayment
}))(PayReturn);
