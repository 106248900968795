import { takeEvery, put, call, delay } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as Api from 'lib/Api';
import * as ActionAuth from 'reducer/AuthReducer';
import * as ActionProxyPay from 'reducer/ProxyPayReducer';
import * as ActionLoad from 'reducer/LoadReducer';
import * as ActionPageState from 'reducer/PageStateReducer';

function* ProxyPaySaga(action) {
  /*대리결제 관련 권리 양도/위임 등록*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  try {
    const formData = action.payload.formData;
    const { data } = yield call(Api.postTransferRight, formData);
    console.log(data);
    yield put(ActionProxyPay.proxyPaySubmitSuccess(data.completedTxNo));
    yield put(ActionPageState.submitState(action.type));
    /*결제상태 종료*/
    yield put(ActionPageState.paymentState(false));
    action.payload.history.push('/Submit');
  } catch (error) {
    /*에러처리*/
    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`잘못된 요청입니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }
    /*세션토큰 만료일시*/
    yield put(ActionProxyPay.proxyPaySubmitFailure(error.response));
    console.log('!세션만료!');
    yield alert('세션이 만료되었습니다.');

    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));

    /*
    if (error.response) {
      yield put(ActionProxyPay.proxyPaySubmitFailure(error.response));
      yield put(ActionPageState.errorState(error.response.status));
      action.payload.history.push('/Error');
    } else {
      console.log('!로그아웃 처리!');
      yield put(ActionAuth.logOut());
      action.payload.history.push('/');
    }*/
  }

  yield put(ActionLoad.hideLoad());
}

export default function* proxyPayLink() {
  yield takeEvery(ActionProxyPay.PROXYPAY_SUBMIT, ProxyPaySaga);
}
