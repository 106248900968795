/*액션상수*/
export const LOGIN_LINK = 'AuthReducer/LOGIN_LINK';
export const LOGIN_LINK_FAILURE = 'AuthReducer/LOGIN_LINK_FAILURE';
export const LOGIN = 'AuthReducer/LOGIN';
export const LOGOUT = 'AuthReducer/LOGOUT';

export const AUTH_IDENTITY = 'AuthReducer/AUTH_IDENTITY';
export const AUTH_IDENTITY_SELF = 'AuthReducer/AUTH_IDENTITY_SELF';
export const AUTH_IDENTITY_PARENTSELF = 'AuthReducer/AUTH_IDENTITY_PARENTSELF';
export const AUTH_PARENTSELF_NAME = 'AuthReducer/AUTH_PARENTSELF_NAME';
export const AUTH_IDENTITY_RESET = 'AuthReducer/AUTH_IDENTITY_RESET';

export const AUTH_CHECK = 'AuthReducer/AUTH_CHECK';
export const AUTH_CHECK_SUCCESS = 'AuthReducer/AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_FAILURE = 'AuthReducer/AUTH_CHECK_FAILURE';

/*액션함수*/
export const logInLink = (history) => ({
  type: LOGIN_LINK,
  payload: { history }
});
export const logInLinkFailure = (error) => ({
  type: LOGIN_LINK_FAILURE,
  payload: { error }
});
export const logIn = () => ({
  type: LOGIN
});
export const logOut = (history) => ({
  type: LOGOUT,
  payload: { history }
});

export const authIdentity = (agreeState, history, identityToken, authType, selfAuthToken, authState) => ({
  type: AUTH_IDENTITY,
  payload: { agreeState, history, identityToken, authType, selfAuthToken, authState }
});
export const authIdentitySelf = (selfAuthToken) => ({
  type: AUTH_IDENTITY_SELF,
  payload: { selfAuthToken }
});
export const authIdentityParentself = (parentSelfAuthToken) => ({
  type: AUTH_IDENTITY_PARENTSELF,
  payload: { parentSelfAuthToken }
});
export const authParentselfName = (parentName) => ({
  type: AUTH_PARENTSELF_NAME,
  payload: { parentName }
});
export const authIdentityReset = () => ({
  type: AUTH_IDENTITY_RESET
});

export const authCheck = (agreeState, history, priceProxy) => ({
  type: AUTH_CHECK,
  payload: { agreeState, history, priceProxy }
});
export const authCheckSuccess = (authState) => ({
  type: AUTH_CHECK_SUCCESS,
  payload: { authState }
});
export const authCheckFailure = (error) => ({
  type: AUTH_CHECK_FAILURE,
  payload: { error }
});

/*기본상태 및 리듀서*/
const initialState = {
  loginState: false,
  logInLinkError: false,
  authState: {},
  selfAuthToken: '',
  parentSelfAuthToken: '',
  parentName: '',
  authCheckError: false
};
export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LINK:
      return {
        ...state
      };

    case LOGIN_LINK_FAILURE:
      return {
        ...state,
        logInLinkError: true
      };

    case LOGIN:
      return {
        ...state,
        loginState: true
      };

    case LOGOUT:
      return {
        ...state,
        loginState: false
      };

    case AUTH_IDENTITY:
      return {
        ...state
      };
    case AUTH_IDENTITY_SELF:
      return {
        ...state,
        selfAuthToken: action.payload.selfAuthToken
      };
    case AUTH_IDENTITY_PARENTSELF:
      return {
        ...state,
        parentSelfAuthToken: action.payload.parentSelfAuthToken
      };
    case AUTH_PARENTSELF_NAME:
      return {
        ...state,
        parentName: action.payload.parentName
      };
    case AUTH_IDENTITY_RESET:
      return {
        ...state,
        selfAuthToken: '',
        parentSelfAuthToken: '',
        parentName: ''
      };

    case AUTH_CHECK:
      return {
        ...state
      };

    case AUTH_CHECK_SUCCESS:
      return {
        ...state,
        authState: action.payload.authState
      };

    case AUTH_CHECK_FAILURE:
      return {
        ...state,
        authCheckError: true
      };

    default:
      return state;
  }
}
