import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { Redirect } from 'react-router-dom';
import numeral from 'numeral';

import { ListSelect } from 'reducer/ListReducer';
import { authCheck } from 'reducer/AuthReducer';
import Text from 'components/Input/Text';

function ProxyPay(props) {
  const [inputValue, setInputValue] = useState(0);
  const [inputSum, setInputSum] = useState(0);
  const [inputSale, setInputSale] = useState(0);
  const { paymentAmount, unpaidAmount } = props.selectList;

  const { register, handleSubmit, errors, reset, setValue, getValues } = useForm({
    mode: 'onChange'
  });

  const goLink = (link) => () => {
    props.history.push(link);
  };

  /**
   * @param {string} agreeState 동의상태->evidence: 휴대폰 납입확인증, proxy: 대리결제 , other: 기타, PROXY_NO_EVIDENCE: 대리결제 증빙없음
   **/
  const chkAuth = (agreeState) => (data, e) => {
    props.authCheck(agreeState, props.history, inputSum);
    e.preventDefault();
  };

  const hanleChangePrice = (e) => {
    if (e.target.value === '') {
      setValue('price_proxy', 0);
      setInputValue(0);
      setInputSum(paymentAmount);
    }
    setValue('price_proxy', numeral(e.target.value).format('0,0'));
    setInputValue(numeral(getValues('price_proxy')).value());
    setInputSum(paymentAmount - numeral(getValues('price_proxy')).value());
  };

  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    setInputSum(paymentAmount);
    setInputSale(Math.floor((paymentAmount * 0.25) / 100) * 100);
  }, [paymentAmount]);

  useEffect(() => {
    if (window.sessionStorage.getItem('selectList')) {
      const selectList = JSON.parse(window.sessionStorage.getItem('selectList'));
      props.ListSelect(selectList);
    }
  }, []);

  return (
    <>
      {props.paymentState ? (
        <div className="inner">
          <div className="col-cont-tit-box mgb0">
            <p className="col-cont-tit">비인가 대리 충전업체를 이용한 경우</p>
            <p className="col-cont-txt mgt30">
              비인가 대리 충전 업체를 통한 결제(이하 "대리결제")는 여러가지 문제를 유발하며 RP의 가치를 훼손시키는
              행위라 할 수 있습니다.
              <br />
              이에 라이엇게임즈는 결제대행사 등과 긴밀히 협조하여 새로운 결제 정책을 적용하는 등 대리결제를 근절하기
              위한 노력을 진행하고 있습니다.
              <br />
              이러한 노력의 일환으로 비인가 대리충전업체와 플레이어 여러분 사이에 발생한 일체의 권리를 양도 받아
              <br />
              대리결제를 진행하는 것 처럼 기만하고 실제로는 해당 요금을 미납한 업체를 대상으로 민, 형사상의 조치도
              고려하고 있습니다.
              <br />
              <br />
              라이엇게임즈는 플레이어 여러분께서 모든 손실을 부담하지 않도록 일부 금액을 미납 요금에서 감면하여 드리고자
              합니다.
            </p>
          </div>

          <div className="col-cont-tit-box mgb20 mgt50">
            <p className="col-cont-tit type-small">증빙 제출 시 납부해야 할 미납요금</p>
            <p className="col-cont-txt mgt10">
              비인가 충전 업체에 관련된 모든 증빙을 성실하게 제출하신 경우 본래 상품 가격의 25% 또는
              <br />그 이하의 금액이 남아있다면 해당 금액만큼 납부하고 계정 잠금을 해제하실 수 있습니다.
            </p>

            <form style={{ width: '360px', margin: '0 auto' }}>
              <Text
                className={'mgt30'}
                name={'price_unpaid'}
                label={'미납금액 (원)'}
                register={register}
                errors={errors}
                defaultValue={numeral(paymentAmount).format('0,0')}
                readOnly={true}
              />
              <Text
                className={'mgt15'}
                name={'price_sale'}
                label={'납부해야 하는 금액 (원)'}
                register={register}
                errors={errors}
                defaultValue={numeral(Math.floor((paymentAmount * 0.25) / 100) * 100).format('0,0')}
                readOnly={true}
              />

              {/*입력용 계산기}
              <Text
                className={'mgt15'}
                name={'price_proxy'}
                hanleChangeP={hanleChangePrice}
                label={'업체에 이미 입금한 금액(원 단위)을 입력해 주세요.'}
                register={register({
                  //required: '값을 입력해주세요'
                  //pattern: {
                  //value: /^[0-9\b]+$/,
                  //message: '숫자가 아닙니다.'
                  //}
                })}
                errors={errors}
              />
              {*/}
            </form>
          </div>

          <div className="col-cont-tit-box mgb50 mgt50">
            <p className="col-cont-tit type-middle">
              {unpaidAmount <= inputSum && unpaidAmount <= inputSale
                ? numeral(unpaidAmount).format('0,0,0')
                : inputSum <= inputSale
                ? numeral(inputSum).format('0,0,0')
                : numeral(inputSale).format('0,0,0')}{' '}
              원을 납부하셔야 합니다.
            </p>
          </div>

          <div className="col-cont-btn-box col-cont-btn-box-column">
            <button
              type="button"
              className={cx(['col-cont-btn', 'type-02'], {
                on: !errors.price_proxy && inputSum > 0
              })}
              onClick={handleSubmit(chkAuth('proxy'))}
            >
              증빙 제출 및 납부하기
            </button>
            <button type="button" className="col-cont-btn" onClick={goLink('/Details/Transaction')}>
              이전으로 돌아가기
            </button>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default connect(
  ({ ListReducer, AuthReducer, PageStateReducer }) => ({
    selectList: ListReducer.selectList,
    loginState: AuthReducer.loginState,
    paymentState: PageStateReducer.paymentState
  }),
  (dispatch) => ({
    ListSelect: bindActionCreators(ListSelect, dispatch),
    authCheck: bindActionCreators(authCheck, dispatch)
  })
)(ProxyPay);
