import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from 'container/Home/Home';
import Details from 'container/Details/Details';
import Unlock from 'container/Unlock/Unlock';
import Done from 'container/Done/Done';
import Fail from 'container/Done/Fail';
import Submit from 'container/Done/Submit';
import Notfound from 'container/Error/Error';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

function MainRoutes() {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/Details" component={Details} />
        <Route path="/Unlock" component={Unlock} />

        <Route path="/Done" component={Done} />
        <Route path="/Fail" component={Fail} />
        <Route path="/Submit" component={Submit} />
        <Route path="*" component={Notfound} />
      </Switch>
    </>
  );
}

export default MainRoutes;
