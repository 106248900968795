import React from 'react';

function FileThumbs(props) {
  const { file, deleteFile } = props;

  return (
    <li>
      {!(file.type === 'application/pdf' || file.type === 'image/tiff') && (
        <div className="sum">
          <img src={file.preview} alt={'preview'} />
        </div>
      )}
      <div className="txt">
        <span>
          {file.path} ({file.size} bytes)
        </span>
        <button type="button" onClick={deleteFile}>
          닫기
        </button>
      </div>
    </li>
  );
}

export default FileThumbs;
