import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from 'reactjs-popup';

import 'assets/scss/contents/Modal/commonModal.scss';

function AssignModal(props) {
  return (
    <Popup
      trigger={<button type="button">[양도 권리 세부 내역 확인 링크], </button>}
      modal
      closeOnDocumentClick
      lockScroll
    >
      <div className="layer-in">
        <div className="col-cont-tit-box mgb0">
          <p className="col-cont-tit mgb0">양도 권리 세부내역</p>
        </div>

        <div className="col-cont-agree-box">
          <div className="col-cont-agree-txt">
            <p>
              1. “대리결제”와 관련하여 본인에게 발생하였거나 장래 발생할 채무불이행, 불법행위, 기타 원인에 따른
              손해배상채권 또는 부당이득반환청구권 등 일체의 권리를 “라이엇게임즈”에 위임합니다.
            </p>
          </div>
          <div className="col-cont-agree-txt">
            <p>2. 상기 권리양도 사실 통지에 관한 일체의 권리를 “라이엇게임즈”에 위임합니다.</p>
          </div>
          <div className="col-cont-agree-txt">
            <p>
              3. 상기 양도 및 권한 위임의 의사표시가 본인 및 법정대리인의 진실한 의사에 의한 것으로 추후 이러한 양도에
              대하여 이의를 제기하지 아니하며, “라이엇게임즈”가 본인으로부터 양도받은 채권을 관련 소송을 통하여 회수할
              경우 이에 대하여 어떠한 권리도 주장하지 아니할 것을 확인합니다.
            </p>
          </div>
        </div>
      </div>
    </Popup>
  );
}

//export default AssignModal;
export default connect(({ LoadReducer }) => ({
  loadState: LoadReducer.loadState
}))(AssignModal);
