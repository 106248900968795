import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import 'assets/scss/contents/Input/fileInput.scss';

function FileInputItem(props) {
  const { name, register, getValues, setValue, errors, idx } = props;
  const [inputFile, setInputFile] = useState([]);

  const hanleChange = (e) => {
    //console.log(getValues(name));
    //getValues(name);
    setInputFile(getValues(name));
  };
  const deleteFile = (e) => {
    setValue(name, null);
    setInputFile([]);
    e.stopPropagation();
  };

  return (
    <div className={cx(['input_file_in'], { on: inputFile.length && !errors[name] })}>
      <input
        type="file"
        name={name}
        id={name}
        ref={register({
          required: idx === '' ? '파일을 첨부해 주세요!' : false,
          pattern: {
            value: /(.*?)\.(jpg|jpeg|png|bmp|pdf)$/,
            message: '파일 형식이 틀립니다.'
          },
          validate: {
            maxSize: (value) => {
              const maxSize = 30 * 1024 * 1024;
              const isFileTooLarge = value.length > 0 && value[0].size > maxSize;
              return !isFileTooLarge || '용량이 너무 큽니다.';
            }
          }
        })}
        onChange={hanleChange}
      />

      <div className="file_item">
        {inputFile.length && !errors[name] ? (
          <>
            <span>{inputFile[0].name}</span>
            <button type="button" className="delete" onClick={deleteFile}>
              삭제
            </button>
          </>
        ) : (
          errors[name] && <p className="err">{errors[name].message}</p>
        )}
      </div>

      <label htmlFor={name} className={cx(['col-cont-btn', 'type-02'], { on: !(inputFile.length && !errors[name]) })}>
        {idx === 4 && inputFile.length && !errors[name] ? <>더이상 첨부가 불가합니다.</> : <>첨부하기</>}
      </label>
    </div>
  );
}

export default FileInputItem;
