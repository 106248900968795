import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Popup from 'reactjs-popup';

import RestrictionList from 'components/Modal/RestrictionList';
import 'assets/scss/contents/Modal/commonModal.scss';
import 'assets/scss/contents/Modal/restrictionModal.scss';

function RestrictionModal(props) {
  const { banInfos } = props;

  return (
    <Popup modal closeOnDocumentClick lockScroll defaultOpen className={'restriction'}>
      {(close) => (
        <div className="layer-in">
          <div className="col-cont-tit-box mgb0">
            <p className="col-cont-tit mgb0">해당 계정으로 이용제한을 받은 게임이 있습니다.</p>
            <span>
              미납요금과 무관하게 이용정지가 발생한 게임은
              <br />
              미납요금을 납부하시더라도 정지가 해제되지 않습니다.
            </span>
          </div>

          <div className="col-cont-agree-box">
            <ul>
              {banInfos.map((data, idx) => {
                return (
                  <RestrictionList
                    key={idx}
                    gameId={data.gameId}
                    restrictionType={data.restrictionType}
                    restrictionReason={data.restrictionReason}
                    banUntil={data.banUntil}
                    comment={data.comment}
                  />
                );
              })}
            </ul>
          </div>

          <button
            type="button"
            className="close"
            onClick={() => {
              //console.log('modal closed ');
              close();
            }}
          >
            닫기
          </button>
        </div>
      )}
    </Popup>
  );
}

//export default RestrictionModal;
export default connect(({ LoadReducer }) => ({
  loadState: LoadReducer.loadState
}))(RestrictionModal);
