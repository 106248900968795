import { combineReducers } from 'redux';
import { LoadReducer } from 'reducer/LoadReducer';
import { AuthReducer } from 'reducer/AuthReducer';
import { PaymentReducer } from 'reducer/PaymentReducer';
import { PhonePayReducer } from 'reducer/PhonePayReducer';
import { ProxyPayReducer } from 'reducer/ProxyPayReducer';
import { ListReducer } from 'reducer/ListReducer';
import { UnlockReducer } from 'reducer/UnlockReducer';
import { PageStateReducer } from 'reducer/PageStateReducer';

const rootReducer = combineReducers({
  /*리듀서 나열*/
  LoadReducer,
  AuthReducer,
  PaymentReducer,
  PhonePayReducer,
  ProxyPayReducer,
  ListReducer,
  UnlockReducer,
  PageStateReducer
});

export default rootReducer;
