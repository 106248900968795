/*액션상수*/
export const PROXY_PAYMENT = 'PaymentReducer/PROXY_PAYMENT';
export const PROXY_PAYMENT_SUCCESS = 'PaymentReducer/PROXY_PAYMENT_SUCCESS';
export const PROXY_PAYMENT_FAILURE = 'PaymentReducer/PROXY_PAYMENT_FAILURE';

export const PAYMENT_REQUEST = 'PaymentReducer/PAYMENT_REQUEST';
export const PAYMENT_REQUEST_SUCCESS = 'PaymentReducer/PAYMENT_REQUEST_SUCCESS';
export const PAYMENT_REQUEST_RESET = 'PaymentReducer/PAYMENT_REQUEST_RESET';
export const PAYMENT_POPUP_RESET = 'PaymentReducer/PAYMENT_POPUP_RESET';
export const PAYMENT_REQUEST_FAILURE = 'PaymentReducer/PAYMENT_REQUEST_FAILURE';

export const PAYMENT_RESPONSE = 'PaymentReducer/PAYMENT_RESPONSE';
export const PAYMENT_RESPONSE_SUCCESS = 'PaymentReducer/PAYMENT_RESPONSE_SUCCESS';
export const PAYMENT_RESPONSE_FAILURE = 'PaymentReducer/PAYMENT_RESPONSE_FAILURE';

/*액션함수*/
export const proxyPayment = (unpaidId, history) => ({
  type: PROXY_PAYMENT,
  payload: { unpaidId, history }
});
export const proxyPaymentSuccess = (proxyPaymentBill) => ({
  type: PROXY_PAYMENT_SUCCESS,
  payload: { proxyPaymentBill }
});
export const proxyPaymentFailure = (error) => ({
  type: PROXY_PAYMENT_FAILURE,
  payload: { error }
});

export const paymentRequest = (unpaidId, method, agreeState, history) => ({
  type: PAYMENT_REQUEST,
  payload: { unpaidId, method, agreeState, history }
});
export const paymentRequestSuccess = (popupPayment) => ({
  type: PAYMENT_REQUEST_SUCCESS,
  payload: { popupPayment }
});
export const paymentRequestReset = () => ({
  type: PAYMENT_REQUEST_RESET
});
export const paymentPopupReset = () => ({
  type: PAYMENT_POPUP_RESET
});
export const paymentRequestFailure = (error) => ({
  type: PAYMENT_REQUEST_FAILURE,
  payload: { error }
});

export const paymentResponse = (clientparam, history) => ({
  type: PAYMENT_RESPONSE,
  payload: { clientparam, history }
});
export const paymentResponseSuccess = (paymentComplete) => ({
  type: PAYMENT_RESPONSE_SUCCESS,
  payload: { paymentComplete }
});
export const paymentResponseFailure = (clientparam) => ({
  type: PAYMENT_RESPONSE_FAILURE,
  payload: { clientparam }
});

/*기본상태 및 리듀서*/
const initialState = {
  proxyPaymentBill: null,
  proxyPaymentError: false,
  popupPayment: null,
  paymentRequestError: false,
  paymentComplete: null,
  paymentResponseError: null
};
export function PaymentReducer(state = initialState, action) {
  switch (action.type) {
    case PROXY_PAYMENT:
      return {
        ...state
      };

    case PROXY_PAYMENT_SUCCESS:
      return {
        ...state,
        proxyPaymentBill: action.payload.proxyPaymentBill
      };

    case PROXY_PAYMENT_FAILURE:
      return {
        ...state,
        proxyPaymentError: true
      };

    case PAYMENT_REQUEST:
      return {
        ...state
      };
    case PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        popupPayment: action.payload.popupPayment
      };
    case PAYMENT_REQUEST_RESET:
      return {
        ...state
      };
    case PAYMENT_POPUP_RESET:
      return {
        ...state,
        popupPayment: null
      };
    case PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        paymentRequestError: true
      };

    case PAYMENT_RESPONSE:
      return {
        ...state
      };
    case PAYMENT_RESPONSE_SUCCESS:
      return {
        ...state,
        popupPayment: action.payload.popupPayment
      };
    case PAYMENT_RESPONSE_FAILURE:
      return {
        ...state,
        paymentResponseError: action.payload.clientparam
      };

    default:
      return state;
  }
}
