import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

import 'assets/scss/contents/Input/textDate.scss';

function TextDate(props) {
  const { name, defaultValue, register, errors } = props;
  //console.log(props);

  const [inputFlag, setInputFlag] = useState(false);
  const [inputCase, setInputCase] = useState('on');
  const [inputAll, setInputAll] = useState([false, false, false]);

  const hanleChange = () => {};

  const handleFocus = () => {
    setInputCase('focus');
    setInputFlag(true);
  };

  const handleBlur = (e) => {
    if (e.target.value) {
      inputAll[e.target.dataset.num] = true;
    } else {
      inputAll[e.target.dataset.num] = false;
    }

    setInputAll(inputAll);
    setInputFlag(false);

    if (inputAll[0] && inputAll[1] && inputAll[2]) {
      setInputFlag(true);
      setInputCase('on');
    }
  };

  useEffect(() => {
    //onsole.log(errors);
  }, [inputFlag, inputCase, inputAll]);

  return (
    <>
      <div className={cx(['input_date'], { [inputCase]: inputFlag || defaultValue })}>
        <div className={cx({ on: inputAll[0] })}>
          <input
            type="text"
            name={`${name}Y`}
            ref={register({
              //required: "값을 입력해 주세요.",
              maxLength: {
                value: 4,
                message: '4자로 입력해 주세요.'
              },
              pattern: {
                value: /^[0-9\b]+$/,
                message: '숫자가 아닙니다.'
              }
            })}
            data-num={0}
            onChange={hanleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <label>년</label>
          <span>YYYY</span>
        </div>
        <div className={cx({ on: inputAll[1] })}>
          <input
            type="text"
            name={`${name}M`}
            ref={register({
              //required: "값을 입력해 주세요.",
              maxLength: {
                value: 2,
                message: '2자로 입력해 주세요.'
              },
              pattern: {
                value: /^[0-9\b]+$/,
                message: '숫자가 아닙니다.'
              }
            })}
            data-num={1}
            onChange={hanleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <label>월</label>
          <span>MM</span>
        </div>
        <div className={cx({ on: inputAll[2] })}>
          <input
            type="text"
            name={`${name}D`}
            ref={register({
              //required: "값을 입력해 주세요.",
              maxLength: {
                value: 2,
                message: '2자로 입력해 주세요.'
              },
              pattern: {
                value: /^[0-9\b]+$/,
                message: '숫자가 아닙니다.'
              }
            })}
            data-num={2}
            onChange={hanleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <label>일</label>
          <span>DD</span>
        </div>
      </div>
      {(errors[`${name}Y`] && <p className="err">{errors[`${name}Y`].message}</p>) ||
        (errors[`${name}M`] && <p className="err">{errors[`${name}M`].message}</p>) ||
        (errors[`${name}D`] && <p className="err">{errors[`${name}D`].message}</p>)}
    </>
  );
}

export default TextDate;
