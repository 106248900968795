import { takeEvery, put, call, delay } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as Api from 'lib/Api';
import * as ActionAuth from 'reducer/AuthReducer';
import * as ActionPayment from 'reducer/PaymentReducer';
import * as ActionLoad from 'reducer/LoadReducer';
import * as ActionPageState from 'reducer/PageStateReducer';

function* ProxyPaymentSaga(action) {
  /*대리결제 예상 납부액 조회*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  try {
    /*거래건 에 대한 idx번호*/
    const unpaidId = action.payload.unpaidId;

    /*예상 납부액 조회api 호출*/
    const { data } = yield call(Api.getUnpaidTransactionsBill, unpaidId);
    //console.log(data);

    /*대리결제 예상 납무액 저장*/
    yield put(ActionPayment.proxyPaymentSuccess(data));

    /*동의 상태초기화*/
    yield put(ActionPageState.agreeState(''));

    /*결제페이지로 이동*/
    action.payload.history.push('/Details/Pay');
  } catch (error) {
    /*에러처리*/

    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`잘못된 요청입니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }

    /*세션토큰 만료일시*/
    yield put(ActionPayment.proxyPaymentFailure(error.response));
    console.log('!세션만료!');
    yield alert('세션이 만료되었습니다.');

    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));

    /*
    if (error.response) {
      yield put(ActionPayment.proxyPaymentFailure(error.response));
      yield put(ActionPageState.errorState(error.response.status));
      action.payload.history.push('/Error');
    } else {
      console.log('!로그아웃 처리!');
      yield put(ActionAuth.logOut());
      action.payload.history.push('/');
    }*/
  }

  yield put(ActionLoad.hideLoad());
}

function* PaymentRequestSaga(action) {
  /*페이레터 결제 요청*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  try {
    console.log(action.payload.unpaidId, action.payload.method, action.payload.agreeState);
    /*결제 요청이 기타(OTHER) 인경우만 인증api호출*/
    if (action.payload.agreeState === 'other') {
      const { status } = yield call(Api.postOtherPayment, JSON.stringify({ unpaidId: action.payload.unpaidId }));
      console.log(status);
    }

    /*결제 요청이 대리결제 증빙없음(PROXY_NO_EVIDENCE) 인경우만 대리결제 무증빙 처리 API호출*/
    if (action.payload.agreeState === 'PROXY_NO_EVIDENCE') {
      const { status } = yield call(Api.postProxyNoEvidence, action.payload.unpaidId);
      console.log(status);
    }

    /*페이레터 결제 요청api호출*/
    const { data } = yield call(Api.getRequestPayment, action.payload.unpaidId, action.payload.method);
    //console.log(data);

    /*응답url로 결제창 호출*/
    window.name = 'mainwin'; //ie 11용
    const popupPayment = window.open(
      data.payletterUrl,
      'identityServicePopup',
      'width=445, height=610, resizable=1, left=0, top=0'
    );

    /*결제창 포인터 저장*/
    yield put(ActionPayment.paymentRequestSuccess(popupPayment));
  } catch (error) {
    /*에러처리*/

    /*400에러처리*/
    if (error.response && error.response.status === 400) {
      yield alert(`잘못된 요청입니다. ${'  '}`);
      yield put(ActionLoad.hideLoad());
      return;
    }

    /*세션토큰 만료일시*/
    yield put(ActionPayment.paymentRequestFailure(error.response));
    console.log('!세션만료!');
    yield alert('세션이 만료되었습니다.');

    const { history } = action.payload;
    yield put(ActionAuth.logOut(history));

    /*
    if (error.response) {
      yield put(ActionPayment.paymentRequestFailure(error.response));
      yield put(ActionPageState.errorState(error.response.status));
      action.payload.history.push('/Error');
    } else {
      console.log('!로그아웃 처리!');
      yield put(ActionAuth.logOut());
      action.payload.history.push('/');
    }*/

    /*에러시에만 로밍바 제거*/
    yield put(ActionLoad.hideLoad());
  }
}

function* PaymentRequestResetSaga(action) {
  console.log('saga!:', action);

  /*결제창 포인터 초기화*/
  yield put(ActionPayment.paymentPopupReset());

  /*에러초기화*/
  yield put(ActionPayment.paymentResponseFailure(null));

  /*로딩바 제거*/
  yield put(ActionLoad.hideLoad());
}

function* PaymentResponseSaga(action) {
  /*페이레터 결제 응답 확인*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  /*완료 또는 실패 타입상태-PaymentReducer/PAYMENT_RESPONSE*/
  yield put(ActionPageState.submitState(action.type));

  /*be 내부에서 처리하기로 바꿈-상태값만 받아서 페이지 이동*/
  console.log(action.payload.clientparam);

  if (action.payload.clientparam.result === 'OK') {
    yield delay(3000);
    action.payload.history.push('/Done');
  } else {
    yield put(ActionPayment.paymentResponseFailure(action.payload.clientparam));
    action.payload.history.push('/Fail');
  }

  /*결제상태 종료*/
  yield put(ActionPageState.paymentState(true));

  yield put(ActionLoad.hideLoad());
}

export function* proxyPaymentLink() {
  yield takeEvery(ActionPayment.PROXY_PAYMENT, ProxyPaymentSaga);
}

export function* paymentRequestLink() {
  yield takeEvery(ActionPayment.PAYMENT_REQUEST, PaymentRequestSaga);
}

export function* paymentRequestResetLink() {
  yield takeEvery(ActionPayment.PAYMENT_REQUEST_RESET, PaymentRequestResetSaga);
}

export function* paymentResponseLink() {
  yield takeEvery(ActionPayment.PAYMENT_RESPONSE, PaymentResponseSaga);
}
