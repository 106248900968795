
/*액션상수*/
export const SHOW_LOAD = "LoadReducer/SHOW_LOAD";
export const HIDE_LOAD = "LoadReducer/HIDE_LOAD";


/*액션함수*/
export const showLoad = () => ({ type: SHOW_LOAD });
export const hideLoad = () => ({ type: HIDE_LOAD });


/*기본상태 및 리듀서*/
const initialState = {
    loadState: false,
};
export function LoadReducer(state=initialState, action) {
    switch (action.type) {
        case SHOW_LOAD:
        return {
            ...state,
            loadState: true,
        };

        case HIDE_LOAD:
        return {
            ...state,
            loadState: false,
        };

        default:
        return state;

    }
}


