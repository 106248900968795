import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import Moment from 'react-moment';
import jwt_decode from 'jwt-decode';

import { logOut } from 'reducer/AuthReducer';
import { fetchDetailList, fetchAccountStatus } from 'reducer/ListReducer';
import { UnlockReducer, unlockRequest } from 'reducer/UnlockReducer';
import Config from 'lib/Config';
import * as Api from 'lib/Api';

function Unlock(props) {
  const { remainingNonpaymentTxCount, banInfos, userName, estimatedBanUntil, proxyCount } = props.accountStatus;
  //console.log(remainingNonpaymentTxCount);

  const onSubmit = () => {
    if (props.pending || remainingNonpaymentTxCount !== 0) return;

    props.unlockRequest(props.history);
  };

  /*기본은 true, 빈배열이 들어올시 true리턴, 모든리스트가 COMPLETE일시 true리턴, true일시 이미 계정장금 신청한경우임*/
  const paymentCheck = (detailLists) => detailLists.reduce((acc, cur) => acc && cur.paymentStatus === 'COMPLETE', true);

  /*확인용 추후삭제*/
  useEffect(() => {
    console.log(paymentCheck(props.detailLists));
  }, [props.detailLists]);

  const hrefLogin = () => {
    alert('로그인이 필요합니다!');
    props.logOut();
  };

  useEffect(() => {
    if (!Api.getCookies(Config.PVPNET_TOKEN_KEY)) hrefLogin();
  }, [Api.getCookies(Config.PVPNET_TOKEN_KEY)]);

  useEffect(() => {
    if (Api.getBearerToken()) {
      /*계정신청 완료된 상태체크위해 리스트 api 콜 - 언락신청 완료후 빈값이들어옴: []*/
      props.fetchDetailList(props.history);
      props.fetchAccountStatus(props.history);
    }
  }, []);

  return (
    <>
      {(Api.getCookies(Config.PVPNET_TOKEN_KEY) && !props.logInLinkError && (
        <div className="inner ty2">
          <div className="col-cont-tit-box mgb35">
            <p className="col-cont-tit">계정잠금 해제</p>
            {(remainingNonpaymentTxCount !== 0 && banInfos && (
              <>
                <p className="col-cont-txt mgt35">
                  {userName} 플레이어님의 결제내역 중 {remainingNonpaymentTxCount}건의 미납요금이 있습니다.
                </p>
                <p className="col-cont-tit type-name mgt35">{userName}</p>
                <p className="col-cont-txt">미납 요금이 남아있어 계정잠금을 해제할 수 없습니다.</p>
              </>
            )) ||
              (remainingNonpaymentTxCount === 0 && (
                <>
                  <p className="col-cont-tit type-name on mgt35">{userName}</p>
                  <p className="col-cont-txt">
                    {proxyCount ? (
                      <>
                        모든 미납요금에 대한 확인 및 납부가 완료되었습니다.
                        <br />
                        서비스 약관 및 운영정책에 따라 7일의 이용제한 조치가 부여됩니다.
                        <br />
                        <br />
                        이용제한 해제 예정일은 클라이언트 로그인 후 확인하실 수 있습니다.
                      </>
                    ) : (
                      <>
                        모든 미납요금에 대한 확인 및 납부가 완료되었습니다.
                        <br />
                        아래 버튼을 누르시면 계정잠금이 해제됩니다.
                      </>
                    )}
                  </p>
                </>
              ))}
          </div>
          <div className="col-cont-btn-box">
            <button
              type="button"
              className={cx(['col-cont-btn', 'type-02'], {
                on: remainingNonpaymentTxCount === 0 && !paymentCheck(props.detailLists)
              })}
              disabled={props.pending}
              onClick={onSubmit}
            >
              계정잠금 해제
            </button>
          </div>
        </div>
      )) || <Redirect to="/" />}
    </>
  );
}

//export default Unlock;
export default connect(
  ({ ListReducer, AuthReducer, UnlockReducer }) => ({
    detailLists: ListReducer.detailLists,
    accountStatus: ListReducer.accountStatus,
    accountStatusError: ListReducer.accountStatusError,
    logInLinkError: AuthReducer.logInLinkError,
    pending: UnlockReducer.pending
  }),
  (dispatch) => ({
    fetchDetailList: bindActionCreators(fetchDetailList, dispatch),
    fetchAccountStatus: bindActionCreators(fetchAccountStatus, dispatch),
    unlockRequest: bindActionCreators(unlockRequest, dispatch),
    logOut: bindActionCreators(logOut, dispatch)
  })
)(Unlock);
