import { takeEvery, put, call, delay } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import * as Api from 'lib/Api';
import * as ActionAuth from 'reducer/AuthReducer';
import * as ActionUnlock from 'reducer/UnlockReducer';
import * as ActionLoad from 'reducer/LoadReducer';
import * as ActionPageState from 'reducer/PageStateReducer';

function* UnlockSaga(action) {
  /*계정 unlock 요청*/
  console.log('saga!:', action);

  yield put(ActionLoad.showLoad());

  /*완료 또는 실패 타입상태-UnlockReducer/UNLOCK_REQUEST*/
  yield put(ActionPageState.submitState(action.type));

  try {
    const { status } = yield call(Api.putAccountStateEnabled);
    //console.log(status);
    yield put(ActionUnlock.unlockSuccess(status));
    if (status === 200) {
      action.payload.history.push('/Done');
    }
  } catch (error) {
    /*에러처리*/
    console.log(error);
    yield put(ActionUnlock.unlockFailure(error.response));
    if (error.response) {
      if (error.response.status === 403) {
        /*세션토큰 만료일시*/
        console.log('!세션만료!');
        yield alert('세션이 만료되었습니다.');

        const { history } = action.payload;
        yield put(ActionAuth.logOut(history));
      } else {
        action.payload.history.push('/Fail');
      }
    } else {
      /*세션토큰 만료일시-cors 대비*/
      console.log('!세션만료!');
      yield alert('세션이 만료되었습니다.');

      const { history } = action.payload;
      yield put(ActionAuth.logOut(history));
    }
  }

  yield put(ActionLoad.hideLoad());
}

export default function* unlockLink() {
  yield takeEvery(ActionUnlock.UNLOCK_REQUEST, UnlockSaga);
}
