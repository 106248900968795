/*액션상수*/
export const PHONEPAY_SUBMIT = 'PhonePayReducer/PHONEPAY_SUBMIT';
export const PHONEPAY_SUBMIT_SUCCESS = 'PhonePayReducer/PHONEPAY_SUBMIT_SUCCESS';
export const PHONEPAY_SUBMIT_FAILURE = 'PhonePayReducer/PHONEPAY_SUBMIT_FAILURE';

/*액션함수*/
export const phonePaySubmit = (formData, history) => ({
  type: PHONEPAY_SUBMIT,
  payload: { formData, history }
});
export const phonePaySubmitSuccess = (status) => ({
  type: PHONEPAY_SUBMIT_SUCCESS,
  payload: { status }
});
export const phonePaySubmitFailure = (error) => ({
  type: PHONEPAY_SUBMIT_FAILURE,
  payload: { error }
});

/*기본상태 및 리듀서*/
const initialState = {
  submitError: false
};
export function PhonePayReducer(state = initialState, action) {
  switch (action.type) {
    case PHONEPAY_SUBMIT:
      return {
        ...state
      };

    case PHONEPAY_SUBMIT_SUCCESS:
      return {
        ...state
      };

    case PHONEPAY_SUBMIT_FAILURE:
      return {
        ...state,
        submitError: true
      };

    default:
      return state;
  }
}
