import React from 'react';

function Footer() {
  return (
    <footer className="c-footer">
      <div className="riotbar-footer lol-theme i18n-ko_KR i18n-ko" lang="ko" style={{ marginTop: 0 }}>
        <div className="riotbar-footer-content default-background">
          <div className="riotbar-footer-riot-logo">
            <a href="http://www.riotgames.com/" target="_blank" rel="noopener noreferrer">
              <svg width="152" height="48" viewBox="0 0 152 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M138.61 41.3332L147.065 42.7555L147.589 48.0043H135.068L135.288 45.9066L144.235 45.5599L144.195 44.591L135.597 43.1066L136.13 37.8399H146.532L146.738 39.9377L138.646 40.2355L138.61 41.3332ZM66.1549 43.871L66.2847 41.9688L71.9988 41.871L72.3346 47.9999H59.2944L60.3199 37.8354H71.7659L71.8958 39.9332L63.0336 40.1377L62.7515 45.7155L69.1955 45.9021L69.2358 43.9732L66.1549 43.871ZM121.428 43.951L121.491 45.7021L129.695 45.9021L129.775 47.9999H118.168L118.499 37.8354H129.569L129.502 39.9288L121.294 40.1199L121.356 41.8932L127.585 41.9688L127.527 43.8799L121.428 43.951ZM87.735 47.9999H91.2413L88.4694 37.8354H80.2431L77.4712 47.9999H80.9775L81.5194 45.4355L87.2111 45.5066L87.735 47.9999ZM81.9448 43.4177L82.6479 40.0932L86.0288 39.9421L86.7364 43.2799L81.9448 43.4177ZM112.552 47.9999L111.527 37.8354H108.58L104.344 42.6799L100.112 37.8354H97.1656L96.1401 47.9999H99.6017L99.9331 41.4088L104.344 45.9021L108.759 41.4088L109.091 47.9999H112.552Z"
                  fill="#F9F9F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M86.4001 32.049H77.3095L74.8824 20.9734L69.0296 24.1468L68.8101 32.049H60.6869L63.1901 7.25787L58 7.4312L58.7613 0.622314H81.9712L85.2044 15.3779L80.4756 17.9423L86.4001 32.049ZM76.1855 6.82676L69.5087 7.04898L69.24 16.7023L76.9468 13.089L76.1855 6.82676ZM97.7878 0.622314H90.8602L90.2288 32.049H98.5983L97.7878 0.622314ZM151.014 0.622314L151.785 7.49787L143.294 7.20898L145.802 32.049H137.414L136.72 6.98231L129.909 6.74676L129.73 0.622314H151.014ZM104.357 0.635641H125.955L127.491 32.049H103.515L104.357 0.635641ZM111.612 25.4579L119.256 25.2134L119.35 7.21342L111.357 6.97342L111.612 25.4579Z"
                  fill="#F9F9F9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 13.9333L29.7166 0L47.1051 4.2L44.0018 34.9289H35.1844L33.5365 12.5422L32.605 12.8311L31.0332 34.9289H24.3653L21.1814 16.3467L20.2499 16.6356L20.4828 34.9289H14.1866L10.5414 19.6222L9.60999 19.9111L10.6579 34.9289H4.59453L0 13.9333ZM22.4307 37.9022L24.6294 43.52L42.712 47.9778L43.6658 38.9866L22.4307 37.9022Z"
                  fill="#F9F9F9"
                />
              </svg>
            </a>
          </div>
          <div className="riotbar-copyright">
            <div className="markup">
              라이엇게임즈코리아 유한회사 서울특별시 강남구 테헤란로 521 30층 (삼성동, 파르나스타워)
              <br />
              대표자 : 조혁진 | 대표전화 : 02-3454-1560 | FAX : 02-3454-1565
              <br />
              사업자등록번호 : 120-87-68488 |{' '}
              <a
                href="http://ftc.go.kr/www/bizCommView.do?key=232&amp;apv_perm_no=2011322016230202718&amp;searchKrwd=1208768488&amp;pageIndex=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                사업자정보확인
              </a>{' '}
              | 통신판매업신고 : 2011-서울강남-02718
            </div>
          </div>
          <div className="riotbar-copyright riotbar-copyright-type-02">
            <div className="markup">
              © 2020 Riot Games . All rights reserved.
              <br />
              League of Legends and Riot games are trademarks or registered trademarks of Riot Games
            </div>
          </div>
          <div className="riotbar-social-links">
            <ul>
              <li>
                <a
                  className="link-youtube link-social"
                  href="https://www.youtube.com/channel/UCooLkG0FfrkPBQsSuC95L6w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="" viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm5.47-16.231c.602.148 1.077.583 1.237 1.136C19 9.908 19 12 19 12s0 2.092-.293 3.095c-.16.553-.635.988-1.238 1.136C16.38 16.5 12 16.5 12 16.5s-4.378 0-5.47-.268c-.602-.149-1.077-.584-1.237-1.137C5 14.092 5 12 5 12s0-2.092.293-3.095c.16-.553.635-.988 1.237-1.136C7.622 7.5 12 7.5 12 7.5s4.378 0 5.47.269zM14.226 12l-3.659-1.9v3.8l3.66-1.9z"
                      fill="#FFF"
                    ></path>
                  </svg>
                  <span className="image-alt-text">YouTube</span>
                </a>
              </li>
              <li>
                <a
                  className="link-facebook link-social"
                  href="https://www.facebook.com/LeagueofLegendsKor?fref=ts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="" viewBox="0 0 24 24">
                    <path
                      d="M24 12c0-6.627-5.373-12-12-12S0 5.373 0 12c0 5.99 4.388 10.954 10.125 11.854V15.47H7.078V12h3.047V9.356c0-3.007 1.792-4.668 4.533-4.668 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.491 0-1.956.925-1.956 1.874V12h3.328l-.532 3.469h-2.796v8.385C19.612 22.954 24 17.99 24 12z"
                      fill="#FFF"
                    ></path>
                  </svg>
                  <span className="image-alt-text">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  className="link-instagram link-social"
                  href="https://www.instagram.com/leagueoflegendskorea/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg className="" viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm2.829-17.698c-.739-.034-.96-.04-2.829-.04-1.87 0-2.09.006-2.829.04-.682.031-1.053.145-1.3.241-.326.127-.56.278-.805.523a2.171 2.171 0 0 0-.523.805c-.096.247-.21.618-.24 1.3-.035.739-.042.96-.042 2.829 0 1.87.007 2.09.041 2.829.031.682.145 1.053.241 1.3.127.326.278.56.523.804.246.245.479.397.805.524.247.096.618.21 1.3.24.738.035.96.042 2.829.042 1.87 0 2.09-.008 2.829-.041.682-.031 1.053-.145 1.3-.241.326-.127.56-.278.804-.524.245-.245.397-.478.524-.805.096-.246.21-.617.24-1.3.035-.738.042-.959.042-2.828 0-1.87-.008-2.09-.041-2.829-.031-.682-.145-1.053-.241-1.3a2.164 2.164 0 0 0-.524-.805 2.172 2.172 0 0 0-.805-.523c-.246-.096-.617-.21-1.3-.24zm-5.715-1.26C9.86 5.008 10.099 5 12 5c1.901 0 2.14.008 2.886.042.745.034 1.254.153 1.7.325.46.18.85.419 1.24.808.389.389.628.78.807 1.24.173.445.291.954.325 1.699.035.746.043.985.043 2.886 0 1.901-.009 2.14-.043 2.886-.034.745-.152 1.254-.325 1.7a3.43 3.43 0 0 1-.807 1.24c-.39.389-.78.628-1.24.807-.445.173-.955.291-1.7.325-.746.034-.985.042-2.886.042-1.9 0-2.14-.008-2.886-.042-.745-.034-1.254-.152-1.7-.325a3.43 3.43 0 0 1-1.239-.808 3.428 3.428 0 0 1-.807-1.24c-.173-.445-.292-.954-.326-1.699C5.008 14.14 5 13.901 5 12c0-1.901.008-2.14.042-2.886.034-.745.153-1.254.326-1.7.178-.46.418-.85.807-1.239.389-.39.78-.628 1.24-.807.445-.173.954-.292 1.699-.326zM12 8.405a3.594 3.594 0 1 0 0 7.19 3.594 3.594 0 0 0 0-7.19zm0 5.928a2.333 2.333 0 1 1 0-4.666 2.333 2.333 0 0 1 0 4.666zm4.577-6.07a.84.84 0 1 1-1.68 0 .84.84 0 0 1 1.68 0z"
                      fill="#FFF"
                    ></path>
                  </svg>
                  <span className="image-alt-text">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
