import { all, fork } from 'redux-saga/effects';
import { watchLogInLink, watchLogOut } from 'saga/LogInLinkSaga';
import { getDetailListLink, getAccountStatusLink } from 'saga/ListSaga';
import unlockLink from 'saga/UnlockSaga';
import phonePayLink from 'saga/PhonePaySaga';
import proxyPayLink from 'saga/ProxyPaySaga';
import { authIdentityLink, authCheckLink } from 'saga/AuthSaga';
import { proxyPaymentLink, paymentRequestLink, paymentRequestResetLink, paymentResponseLink } from 'saga/PaymentSaga';

function* rootSaga() {
  yield all([
    /*--사가 나열--*/
    /*로그인 로그아웃*/
    fork(watchLogInLink),
    fork(watchLogOut),

    /*정보*/
    fork(getDetailListLink),
    fork(getAccountStatusLink),

    /*정지해제*/
    fork(unlockLink),

    /*전송*/
    fork(phonePayLink),
    fork(proxyPayLink),

    /*인증*/
    fork(authIdentityLink),
    fork(authCheckLink),

    /*결제*/
    fork(proxyPaymentLink),
    fork(paymentRequestLink),
    fork(paymentRequestResetLink),
    fork(paymentResponseLink)
  ]);
}

export default rootSaga;
