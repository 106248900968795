import React, { useEffect } from 'react';
import cx from 'classnames';
import Moment from 'react-moment';

function RestrictionList(props) {
  const { gameId, restrictionType, restrictionReason, banUntil, comment } = props;

  return (
    <li
      className={cx({ lol: gameId === 'league_of_legends', lor: gameId === 'bacon', valorant: gameId === 'valorant' })}
    >
      <div className="tit">
        {(gameId === 'league_of_legends' && <span>리그 오브 레전드</span>) ||
          (gameId === 'bacon' && <span>레전드 오브 룬테라</span>) ||
          (gameId === 'valorant' && <span>발로란트</span>)}
      </div>
      <div className="txt">
        {(restrictionType === 'PERMANENT_BAN' && <span>영구 정지</span>) ||
          (restrictionType === 'TIME_BAN' && (
            <span>
              <Moment format="YYYY. MM. DD. ">{banUntil}</Moment>까지 정지
            </span>
          ))}
      </div>
    </li>
  );
}

export default RestrictionList;
