import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';

import MainRoutes from 'components/Routes/MainRoutes';
import * as Api from 'lib/Api';
import Header from 'container/Layout/Header';
import Footer from 'container/Layout/Footer';
import Loading from 'components/Loading/Loading';
import { logInLink } from 'reducer/AuthReducer';
import AppVersion from 'components/AppVersion';

function Layout(props) {
  //console.log(props);

  useEffect(() => {
    /*새션토큰 변화만 있을시 로그인 링크 액션 호출해서 로직실행-모든페이지는 layout 컴퍼넌트 거쳐감*/
    props.logInLink(props.history);
  }, [Api.getBearerToken()]);

  return (
    <>
      {/* <Header location={props.location} history={props.history} /> */}
      <article className="container">
        <section className="visual">
          <div className="conts-wrap">
            <div className="col-wrap">
              <MainRoutes />
            </div>
          </div>
        </section>
      </article>
      <Footer location={props.location} />
      <AppVersion />
      {props.loadState && <Loading />}
    </>
  );
}

export default connect(
  ({ LoadReducer }) => ({
    loadState: LoadReducer.loadState
  }),
  (dispatch) => ({
    logInLink: bindActionCreators(logInLink, dispatch)
  })
)(withRouter(Layout));
