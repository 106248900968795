import React, { useEffect }   from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const $ = window.$;

function HomeToggle({toggle}) {
	
	useEffect(() => {

		$(".home-list li .list-top").on("click",function(e){
			$(this).parent().parent().siblings().find(".list-top").removeClass("on");
			$(this).parent().parent().siblings().find(".list-top").next().slideUp();
			$(this).toggleClass("on");
			if($(this).next().is(":visible")){
				$(this).next().slideUp();
			}
			else{
				$(this).next().slideDown();
			}
		});

		return () =>{
			$(".home-list li .list-top").off("click");
		}
	},[toggle]);
	

	return (
		<>
			{
				toggle.length ===0 ?
				<li className="none">
					리스트가 없습니다.
				</li>
				:
				toggle.map((item, idx) => { 
					return (
						<li key={item.id}>
							<div className="list-in">
								<div className="list-top">
									{item.question}
								</div>
								<div className="list-cont">
									{item.answer}
								</div>
							</div>
						</li>
					)
				})

			}
		</>
	);
}


//export default HomeToggle;
export default connect(
	({LoadReducer, AuthReducer}) => ({
		loadState: LoadReducer.loadState,
		loginState: AuthReducer.loginState,
	})
)(HomeToggle);
