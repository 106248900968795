import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import FileInputItem from 'components/Input/FileInputItem';
import 'assets/scss/contents/Input/fileInput.scss';

function FileMultipleInput(props) {
  const { name, register, getValues, setValue, errors } = props;

  return (
    <div className="input_file">
      <div className="input_items">
        {[...Array(5)].map((n, idx) => {
          if (idx === 0) idx = '';
          return (
            <FileInputItem
              key={idx}
              idx={idx}
              name={name + idx}
              register={register}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
            />
          );
        })}
      </div>
      <div className="alert">최대 30mb 이내의jpg, jpeg, png, bmp, pdf 파일을 5개까지 등록하실 수 있습니다.</div>
    </div>
  );
}

export default FileMultipleInput;
