import Cookies from 'js-cookie';
import axios from 'axios';
import Config from 'lib/Config';

const BaseURL = process.env.REACT_APP_ANTI_FRAUD_API;

export const addStorage = (key, value = '') => {
  sessionStorage.setItem(key, value);
};

export const getStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const removeStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getCookies = (key) => {
  return Cookies.get(key);
};

export const getBearerToken = () => {
  return getStorage(Config.BEARERTOKEN);
};

//.........로그인............//
/*Session toekn 발급 -클리어*/
export const postSessionToken = () => {
  return axios({
    method: 'post',
    url: `${BaseURL}/unpaid/v1/session`,
    withCredentials: true
  });
  /*
{   "accessToken": "this-is-access-token-with-jwt-format" }
*/
};

/*Sesion token 삭제 -클리어*/
export const deleteSessionToken = () => {
  const access_token = getBearerToken();
  return axios({
    method: 'delete',
    url: `${BaseURL}/unpaid/v1/session`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

//.........인증............//
/*디코드 인증토큰-클리어*/
export const getPersonalInfo = (identityToken) => {
  const access_token = getBearerToken();
  const url = `${BaseURL}/unpaid/v1/selfauth/${identityToken}`;
  return axios({
    method: 'get',
    url: url,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

/*본인인증 결과 조회 -클리어*/
export const getIdentityAuth = (authType) => {
  const access_token = getBearerToken();
  return axios({
    method: 'get',
    url: `${BaseURL}/unpaid/v1/identity/${authType}`,
    //url:`http://localhost:5000/identity/${authType}`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
  /*
{  "userName":"user1",  "puuid":"752e94c6-0210-5d73-a1f5-2ebd74ca5254",  "realName":"홍길동"   ,  "age":13,  "selfAuth":true,  "parentSelfAuth":false } 
*/
};

/*본인인증 결과 저장 -클리어*/
export const postIdentityAuth = (authType, jsonData) => {
  const access_token = getBearerToken();
  console.log(authType, jsonData);
  return axios({
    method: 'post',
    url: `${BaseURL}/unpaid/v1/identity/${authType}`,
    //url:`http://localhost:5000/payment-evidence`,
    data: jsonData,
    headers: { Authorization: `Bearer ${access_token}`, 'Content-type': 'application/json' }
  });
  /*
{  "userName":"user1",  "puuid":"752e94c6-0210-5d73-a1f5-2ebd74ca5254",  "realName":"홍길동"   ,  "age":13,  "selfAuth":true,  "parentSelfAuth":false } 
*/
};

//.........제출............//
/*휴대폰 납부확인증 등록 -클리어*/
export const postPaymentEvidence = (formData) => {
  const access_token = getBearerToken();
  return axios({
    method: 'post',
    url: `${BaseURL}/unpaid/v1/payment-evidence`,
    //url:`http://localhost:5000/payment-evidence`,
    data: formData,
    headers: { Authorization: `Bearer ${access_token}`, 'Content-type': 'multipart/form-data' }
  });
};

/*대리결제 관련 권리 양도/위임 등록 -클리어*/
export const postTransferRight = (formData) => {
  const access_token = getBearerToken();
  return axios({
    method: 'post',
    url: `${BaseURL}/unpaid/v1/transfer-right`,
    //url:`http://localhost:5000/transfer-right`,
    data: formData,
    headers: { Authorization: `Bearer ${access_token}`, 'Content-type': 'multipart/form-data' }
  });
  /*
{"completedTxNo": "12345"} 
*/
};

//.........조회............//
/*전체 미수금 현황 목록 조회 -클리어*/
export const getUnpaidTransactions = () => {
  const access_token = getBearerToken();
  return axios({
    method: 'get',
    url: `${BaseURL}/unpaid/v1/unpaid-transactions`,
    //url:`http://localhost:5000/unpaid`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

/*계정상태조회 -클리어*/
export const getAccountState = () => {
  const access_token = getBearerToken();
  return axios({
    method: 'get',
    url: `${BaseURL}/unpaid/v1/account/state`,
    //url:`http://localhost:5000/status`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

/*대리결제 예상 납부액 조회 -클리어*/
export const getUnpaidTransactionsBill = (unpaidId) => {
  const access_token = getBearerToken();
  return axios({
    method: 'get',
    url: `${BaseURL}/unpaid/v1/unpaid-transactions/${unpaidId}/bill`,
    //url:`http://localhost:5000/bill`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

//.........결제............//
/*대리결제 무증빙 처리 (대리결제를 선택하고 증빙 없이 처리할 때 호출) -클리어*/
export const postProxyNoEvidence = (unpaidId) => {
  const access_token = getBearerToken();
  return axios({
    method: 'post',
    url: `${BaseURL}/unpaid/v1/proxy?unpaidId=${unpaidId}`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

/*기타 납입 정보 입력 (기타로 진입시 결제 승인시킴) -클리어*/
export const postOtherPayment = (jsonData) => {
  const access_token = getBearerToken();
  return axios({
    method: 'post',
    url: `${BaseURL}/unpaid/v1/other`,
    //url:`http://localhost:5000/other`,
    data: jsonData,
    headers: { Authorization: `Bearer ${access_token}`, 'Content-type': 'application/json' }
  });
};

/*페이레터 결제 요청 -클리어*/
export const getRequestPayment = (unpaidId, method) => {
  const access_token = getBearerToken();
  return axios({
    method: 'get',
    url: `${BaseURL}/unpaid/v1/payment/request?unpaidId=${unpaidId}&method=${method}`,
    //url:`http://localhost:5000/paymentRequest`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};

//.........해제............//
/*계정 unlock 요청 -클리어*/
export const putAccountStateEnabled = () => {
  const access_token = getBearerToken();
  return axios({
    method: 'put',
    url: `${BaseURL}/unpaid/v1/account/state/enabled`,
    //url:`http://localhost:5000/enabled`,
    headers: { Authorization: `Bearer ${access_token}` }
  });
};
