/*액션상수*/
export const ERROR_STATE = 'PageStateReducer/ERROR_STATE';
export const SUBMIT_STATE = 'PageStateReducer/SUBMIT_STATE';
export const AGREE_STATE = 'PageStateReducer/AGREE_STATE';
export const PAYMENT_STATE = 'PageStateReducer/PAYMENT_STATE';

/*액션함수*/
export const errorState = (errorState) => ({
  type: ERROR_STATE,
  payload: { errorState }
});
export const submitState = (submitState) => ({
  type: SUBMIT_STATE,
  payload: { submitState }
});
export const agreeState = (agreeState) => ({
  type: AGREE_STATE,
  payload: { agreeState }
});
export const paymentState = (paymentState) => ({
  type: PAYMENT_STATE,
  payload: { paymentState }
});

/*기본상태 및 리듀서*/
const initialState = {
  errorState: '',
  submitState: '',
  agreeState: '',
  paymentState: false
};
export function PageStateReducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_STATE:
      return {
        ...state,
        errorState: action.payload.errorState
      };

    case SUBMIT_STATE:
      return {
        ...state,
        submitState: action.payload.submitState
      };

    case AGREE_STATE:
      return {
        ...state,
        agreeState: action.payload.agreeState
      };

    case PAYMENT_STATE:
      return {
        ...state,
        paymentState: action.payload.paymentState
      };

    default:
      return state;
  }
}
