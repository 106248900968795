/*액션상수*/
export const UNLOCK_REQUEST = 'UnlockReducer/UNLOCK_REQUEST';
export const UNLOCK_SUCCESS = 'UnlockReducer/UNLOCK_SUCCESS';
export const UNLOCK_FAILURE = 'UnlockReducer/UNLOCK_FAILURE';

/*액션함수*/
export const unlockRequest = (history) => ({
  type: UNLOCK_REQUEST,
  payload: { history }
});
export const unlockSuccess = (status) => ({
  type: UNLOCK_SUCCESS,
  payload: { status }
});
export const unlockFailure = (error) => ({
  type: UNLOCK_FAILURE,
  payload: { error }
});

/*기본상태 및 리듀서*/
const initialState = {
  unlockRequest: false,
  error: false,
  pending: false
};
export function UnlockReducer(state = initialState, action) {
  switch (action.type) {
    case UNLOCK_REQUEST:
      return {
        ...state,
        pending: true
      };

    case UNLOCK_SUCCESS:
      return {
        ...state,
        unlockRequest: true,
        pending: false
      };

    case UNLOCK_FAILURE:
      return {
        ...state,
        error: true,
        pending: false
      };

    default:
      return state;
  }
}
