import React from 'react';

import AssignModal from 'components/Modal/AssignModal';
import PrivacyModal from 'components/Modal/PrivacyModal';

function Proxy(props) {
  return (
    <div className="col-cont-agree-box">
      <p className="col-cont-agree-tit">
        본인은 [{props.realName ? props.realName : '계정 소유자 성명'}]의 법정대리인인 [
        {props.parentName ? props.parentName : '법정대리인 성명'}]으로 아래 사항을 확인하고 이에 동의합니다.
      </p>

      <div className="col-cont-agree-txt">
        <p>
          • 본인은 [{props.realName ? props.realName : '계정 소유자 성명'}]의 법정대리인으로서 [
          {props.realName ? props.realName : '계정 소유자 성명'}]이 라이엇게임즈코리아 유한회사 (이하 “라이엇게임즈”)가
          제공하는 게임인 리그 오브 레전드 및 레전드 오브 룬테라 (이하 “게임”)를 이용하는 중 “게임”내 아이템을 구매하는
          데 필요한 재화인 라이엇 포인트 및/또는 코인을 제3자에게 대신 결제하도록 의뢰(이하 “대리결제”)한 것과 관련하여
          발생하였거나 장래 발생할 채무불이행, 불법행위 기타 원인에 따른 손해배상채권 또는 부당이득반환청구권 등 일체의
          권리를 “라이엇게임즈”에게 양도하고 이에 필요한 통지권한을 “라이엇게임즈”에 위임하는 것에 동의합니다.{' '}
        </p>
      </div>

      <div className="col-cont-agree-txt">
        <p>
          • “라이엇게임즈” 상기 권리 양도 및 통지권한 위임에 필요한 [
          {props.realName ? props.realName : '계정 소유자 성명'}]의 개인정보를 수집, 이용 및 위탁하는 것에 동의합니다.
        </p>
      </div>
      <div className="col-cont-agree-txt">
        <p>구체적인 양도 내역 및 개인정보 수집, 이용 및 위탁 내역은 다음의 링크에서 확인하세요: </p>
        <div className="txtc mgt10">
          <AssignModal />
          <PrivacyModal />
        </div>
      </div>
    </div>
  );
}

export default Proxy;
