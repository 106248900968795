import React from 'react';
import './appVersion.scss';

const AppVersion = () => (
  <div className="app-version-container">
    <span className="app-version">{process.env.REACT_APP_VERSION}</span>
  </div>
);

export default AppVersion;
